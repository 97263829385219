import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IStateDto } from "../shared/IState";
import { Observable } from "rxjs";
import { FileUploadDTO } from "../models/file-upload-dto";
import { PreBindDocumentDTO } from "../models/pre-bind-document-dto";
import { PreBindStatusDTO } from "../models/pre-bind-status-dto";
import { PreBindSign } from "../models/pre-bind-sign";

@Injectable({
  providedIn: 'root'
})

export class MortgageeService {
  constructor(private httpClient: HttpClient, @Inject('ARC_API_URL') private arcApiBaseUrl: string) { }

  getStates(): Observable<IStateDto[]> {
    return this.httpClient.get<IStateDto[]>(this.arcApiBaseUrl+ '/api/1000/hiscox/mortgagee/states');
  }

  getQuoteId(number) {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/number/${number}`);
  }

  expiredCheck(number) {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/number/${number}/expired`);
  }

  mortgageesByQuoteNumber(number) {
    let arcApiBaseUrl = "http://localhost:50290/";

    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/mortgagee/quote/${number}`);
  }

  getRatedQuote(quoteNumber, user): Observable<any> {
    var arcApiBaseUrl = "http://localhost:50290/";

    return this.httpClient.post<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/${quoteNumber}`, user);
  };

  getRiskAddress(quoteNumber): Observable<any> {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/number/${quoteNumber}/riskAddress`);
  }

  createMortgagee(mortgagee) {
    return this.httpClient.post<any>(this.arcApiBaseUrl + '/api/1000/hiscox/mortgagee', mortgagee);
  }

  updateMortgagee(mortgagee) {
    return this.httpClient.post<any>(this.arcApiBaseUrl + '/api/1000/hiscox/mortgagee/update', mortgagee);
  }

  removeMortgagee(mortgagee) {
    return this.httpClient.post<any>(this.arcApiBaseUrl + '/api/1000/hiscox/mortgagee/remove', mortgagee);
  }

  updateAdditionalInsured(ai) {
    return this.httpClient.post<any>(this.arcApiBaseUrl + '/api/1000/hiscox/quote/additionalInsured', ai);
  }

  getAdditionalInsured(quoteNumber): Observable<any[]>{
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/number/${quoteNumber}/additionalinsured`);
  }

  removeAdditionalInsured(id): Observable<any> {
    return this.httpClient.delete<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/additionalinsured/${id}`);
  }

  /*******PRE BIND METHODS ABOVE**********/

  uploadFloridaFormsManuallySigned(fileForms: Array<FileUploadDTO>){
    return this.httpClient.post<any>(this.arcApiBaseUrl + '/api/documents/UploadSignedFloridaForms', fileForms);
  }

  getPreBindDocuments(quoteId: number){
    return this.httpClient.get<Array<PreBindDocumentDTO>>(this.arcApiBaseUrl + `/api/documents/GetPreBindDocuments/${quoteId}`);
  }

  isQuoteApproved(quoteId: number){
    return this.httpClient.get<boolean>(this.arcApiBaseUrl + `/api/documents/IsQuoteApproved/${quoteId}`);
  }

  getPreBindStatus(quoteId: number){
    return this.httpClient.get<PreBindStatusDTO>(this.arcApiBaseUrl + `/api/documents/GetPreBindSignStatus/${quoteId}`);
  }

  getPreBindSign(quoteId: number){
    return this.httpClient.get<PreBindSign>(this.arcApiBaseUrl + `/api/documents/GetPreBindSign/${quoteId}`);
  }

  signViaDocuSign(quoteId: number, userId: number, insuredEmail: string){
    return this.httpClient.post<boolean>(this.arcApiBaseUrl + `/api/documents/SignViaDocuSign`, {
      QuoteId: quoteId,
      UserId: userId,
      InsuredEmail: insuredEmail
    });
  }
}
