import { Component } from "@angular/core";
import { NgbDateStruct, NgbDate, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { OptionsService } from "./options.service";
import { UserDto } from "../shared/userDto.model";
import { RoleMapperService } from "../shared/role-mapper.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CustomNgbDateFormat } from "../shared/Custom-NgbDateParserFormat";
import { RedirectService } from "../shared/redirect.service";
import { DropdownService } from "../shared/dropdown.service";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-options-component',
  templateUrl: './options.component.html'
})

export class OptionsComponent {
  user: UserDto;
  quoteModel: any;
  userRole: string;
  clientInformation: string;
  riskAddress: string;
  riskAddressState: any;
  deductibleCategories: any[];
  selectedDeductibleCategory: any;
  waitingPeriods: any[];
  selectedWaitingPeriodCategory: any;
  effectiveDate: NgbDateStruct;
  visibleOptions: any[];
  previousVisibleOptions: any[];
  effectiveDateOneWeek: boolean;
  pastEffDate: boolean;
  showSpinner: boolean = true;
  max: Date;
  maxDate: any = {};
  pastMax: boolean = false;
  rejectedDocuments: boolean = true;

  constructor(private optionsService: OptionsService, 
    private dropdownService: DropdownService, 
    private roleMapper: RoleMapperService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private redirectService: RedirectService) {
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      let user = params['u'];

      if (user != undefined || user != null) {
        this.decodeUser(user);

        let quoteNumber = params['q'];
        var quote = {
          quoteNumber: Number(quoteNumber)
        };

        sessionStorage.setItem('quote', JSON.stringify(quote));

        let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
        this.router.navigateByUrl(url);
      }
    });

    let today = new Date();
    this.user = new UserDto();
    let quote = JSON.parse(sessionStorage.getItem('quote'));

    if (quote === undefined || quote == null) {
      this.router.navigate(['/application']);
    } else {

      this.max = new Date();
      this.max.setDate(this.max.getDate() + 60);
      this.max.setHours(0);
      this.max.setMinutes(0);
      this.max.setSeconds(0);
      this.max.setMilliseconds(0);

      this.maxDate = { year: this.max.getFullYear(), month: this.max.getMonth() + 1, day: this.max.getDate() };

      this.optionsService.checkQuoteStage(quote.quoteNumber).subscribe(response => {
        if (response == '/options') {
          this.waitingPeriods = this.dropdownService.getWaitingPeriodTypes();
          this.userRole = this.roleMapper.mapRole(sessionStorage.getItem('user_roles'));
          this.user.username = sessionStorage.getItem('user_agency');
          this.user.agencyNumber = sessionStorage.getItem('user_agency');
          this.user.userid = Number(sessionStorage.getItem("user_id"));
          this.user.agentid = Number(sessionStorage.getItem("agentId"));
          this.user.agencyid = Number(sessionStorage.getItem("agencyId"));
          this.user.role = this.userRole;

          this.riskAddress = '';

          this.optionsService.getRiskAddress(quote.quoteNumber).subscribe(response => this.setRiskAddress(response));
          this.optionsService.getRatedQuote(quote.quoteNumber, this.user).subscribe(response => {
            //console.log(response)
            this.setModel(response);
            this.optionsService.GetRejectedDocumentValidation(quote.quoteNumber).subscribe(response => {
              this.rejectedDocuments = response;
            })
          });
          this.showSpinner = false;
        }
        else if (response === '/policy') {
          this.redirectService.policyByQuoteNumber(quote.quoteNumber).subscribe(response => this.redirectService.xenithRedirect(response));
        } else {
          this.router.navigate([response]);
        }
      });
    }
  }

  decodeUser(obj) {
    var userObject = JSON.parse(atob(obj));

    sessionStorage.setItem("user_id", userObject.userId);
    sessionStorage.setItem("name", userObject.name);
    sessionStorage.setItem("permissions", JSON.stringify(userObject.user_permissions));
    sessionStorage.setItem("refreshToken", userObject.refreshToken);
    sessionStorage.setItem("user_token", userObject.user_token);
    sessionStorage.setItem("state_access", userObject.state_access);
    sessionStorage.setItem("token_expiration", userObject.token_expiration);
    sessionStorage.setItem("user_agency", userObject.agency);
    sessionStorage.setItem('user_roles', JSON.stringify(userObject.user_roles));
    sessionStorage.setItem('agentId', userObject.agentId);
    sessionStorage.setItem('agencyId', userObject.agencyId);
  }

  setRiskAddress(response) {
    this.riskAddress = response.Street1 + ((response.Street2 == null || response.Street2 == "") ? "" : (" " + response.Street2)) + " " + response.City + ", " + response.State + " " + response.Zip;
    this.riskAddressState = response.State;
  }

  setModel(response) {
    this.quoteModel = response;

    if (response.EffectiveDate != null) {
      let effDate = new Date(response.EffectiveDate);
      this.effectiveDate = new NgbDate(effDate.getFullYear(), effDate.getMonth() + 1, effDate.getDate());
    } else {
      let effDate = new Date();
      effDate.setDate(effDate.getDate() + 7);

      this.effectiveDate = new NgbDate(effDate.getFullYear(), effDate.getMonth() + 1, effDate.getDate());
    }

    let mappedOptions = this.quoteModel.Quote.Options.map(options => options.DeductibleCategory);
    let uniqueOptions = mappedOptions.filter((item, index, array) => index === array.indexOf(item));
    let numberArray = uniqueOptions.map((options, index) => ({ id: index, option: Number(options.replace(/[^0-9\.-]+/g, "")) }));
    let sortedNumberArray = numberArray.sort((a, b) => (a.option > b.option) ? 1 : ((b.option > a.option) ? -1 : 0));

    this.deductibleCategories = this.reorder(uniqueOptions, sortedNumberArray);
    this.selectedDeductibleCategory = this.deductibleCategories[0];

    //vm.loading = false;

    this.clientInformation = this.quoteModel.ClientName;
    this.visibleOptions = this.quoteModel.Quote.Options.filter(option => option.DeductibleCategory === this.selectedDeductibleCategory);

    if (this.riskAddressState == 'TX') {
      this.assignTaxes();
    }

    this.previousVisibleOptions = this.visibleOptions;

    if (response.WaitingPeriodTypeId != null) {
      this.selectedWaitingPeriodCategory = this.waitingPeriods.filter(cat => cat.Id === response.WaitingPeriodTypeId)[0];
    } else {
      this.selectedWaitingPeriodCategory = this.waitingPeriods[0];
    }
  }

  reorder(mappedArray, indexArray): any[] {
    let temp = [];

    for (var i = 0; i < mappedArray.length; i++) {
      temp.push(mappedArray[indexArray[i].id]);
    }

    return temp;
  }

  requote() {
    sessionStorage.setItem("requoteId", this.quoteModel.QuoteId);

    this.router.navigate(['/application']);
  }

  printOptions() {
    this.showSpinner = true;

    let effDate = new Date(this.effectiveDate.year, this.effectiveDate.month - 1, this.effectiveDate.day);
    let optionObject = {
      effectiveDate: effDate,
      deductibleOption: this.selectedDeductibleCategory,
      quoteNumber: this.quoteModel.QuoteId
    };

    this.optionsService.printQuoteOptions(optionObject).subscribe(response => {
      var filename = response.FileDownloadName;
      var byteCharacters = atob(response.FileContents);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);

      var fileType = 'application/pdf';

      var blob = new Blob([byteArray], { type: fileType });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      }
      else {
        var objectUrl = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = objectUrl;
        link.download = filename;
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(objectUrl);
        });
      }

      this.showSpinner = false;
    });
  }

  range(min, max, step) {
    step = step || 1;

    let input = [];

    for (var i = min; i <= max; i += step) {
      input.push(i);
    }

    return input;
  }

  onDeductibleChange(event) {
    if (this.previousVisibleOptions !== event) {
      this.previousVisibleOptions = this.visibleOptions;
      this.visibleOptions = this.quoteModel.Quote.Options.filter(option => option.DeductibleCategory === this.selectedDeductibleCategory);

      this.previousVisibleOptions.forEach(prevOpt => {
        prevOpt.noPremChange = false;

        this.visibleOptions.forEach(currentOpt => {
          if (currentOpt.OptionName === prevOpt.OptionName && currentOpt.Premium == prevOpt.Premium) {
            currentOpt.noPremChange = true;
          }
        });
      });
    }
  }

  checkDate(date: any, valid) {
    
    if (valid) {
      
      var today = new Date();

      var oneWeek = new Date();
      oneWeek.setDate(oneWeek.getDate() + 7);

      oneWeek.setHours(0);
      oneWeek.setMinutes(0);
      oneWeek.setSeconds(0);
      oneWeek.setMilliseconds(0);

      let effDate = new Date(date.year, date.month - 1, date.day);

      effDate.setHours(0);
      effDate.setMinutes(0);
      effDate.setSeconds(0);
      effDate.setMilliseconds(0);

      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      
      
      var submitDatePlusSevenDays = new Date();
      var requestDate = new Date(this.quoteModel.RequestDate);
      let dateRequest = requestDate.toLocaleDateString();
      //console.log(dateRequest)
      let dateArray = dateRequest.split("/");
      let requestDateCorrectFormat = new Date(+dateArray[2], +dateArray[0] - 1, +dateArray[1]);
      dateRequest = today.toLocaleDateString();
      //console.log(dateRequest)
      dateArray = dateRequest.split("/");
      var sevenDaysAgo = new Date(+dateArray[2], +dateArray[0] - 1, +dateArray[1] - 7);
      submitDatePlusSevenDays.setDate(requestDateCorrectFormat.getDate() + 7);
      if (effDate < today) {
        this.pastEffDate = true;
      }
      else {
        this.pastEffDate = false;
      }

      if (this.selectedWaitingPeriodCategory.Id == 2 || this.selectedWaitingPeriodCategory.Id == 3 || effDate >= oneWeek) {
        this.effectiveDateOneWeek = false;
      }
      else {
        this.effectiveDateOneWeek = true;
      }

      if (this.riskAddressState == 'TX') {
        this.assignTaxes();
      }

      if (this.riskAddressState == 'FL' && (this.selectedWaitingPeriodCategory.Id == 2 || this.selectedWaitingPeriodCategory.Id == 3) && this.rejectedDocuments) 
      {
        // console.log(requestDateCorrectFormat)
        // console.log(effDate)
        // console.log(submitDatePlusSevenDays)
        // console.log(sevenDaysAgo)
        // console.log(requestDateCorrectFormat < today)
        // console.log(effDate >= requestDateCorrectFormat)
        // console.log(effDate <= submitDatePlusSevenDays)
        // console.log(effDate >= sevenDaysAgo)
        if(requestDateCorrectFormat < today && effDate >= requestDateCorrectFormat && effDate <= submitDatePlusSevenDays && effDate >= sevenDaysAgo){
          this.pastEffDate = false;
        }
      }
    }

    if (date != null || date != undefined) {
      let effDate = new Date(date.year, date.month - 1, date.day);
      this.pastMax = effDate > this.max;
    } else {
      this.pastMax = false;
    }
  }

  selectQuoteOption(option) {
    //console.log("entro")
    let effDate = new Date(this.effectiveDate.year, this.effectiveDate.month - 1, this.effectiveDate.day);
    let today = new Date();
    let oneWeek = new Date();

    oneWeek.setDate(oneWeek.getDate() + 7);
    oneWeek.setHours(0);
    oneWeek.setMinutes(0);
    oneWeek.setSeconds(0);
    oneWeek.setMilliseconds(0);

    effDate.setHours(0);
    effDate.setMinutes(0);
    effDate.setSeconds(0);
    effDate.setMilliseconds(0);

    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    var submitDatePlusSevenDays = new Date();
    var requestDate = new Date(this.quoteModel.RequestDate);
    let dateRequest = requestDate.toLocaleDateString();    
    let dateArray = dateRequest.split("/");
    let requestDateCorrectFormat = new Date(+dateArray[2], +dateArray[0] - 1, +dateArray[1]);
    dateRequest = today.toLocaleDateString();
    dateArray = dateRequest.split("/");
    var sevenDaysAgo = new Date(+dateArray[2], +dateArray[0] - 1, +dateArray[1] - 7);
    submitDatePlusSevenDays.setDate(requestDateCorrectFormat.getDate() + 7);
    let continueQuote = () => {
      this.showSpinner = true;

      if (((this.selectedWaitingPeriodCategory.Id == 2 || this.selectedWaitingPeriodCategory.Id == 3) && effDate >= today) || effDate >= oneWeek) {
        let model = {
          effectiveDate: effDate,
          optionNumber: option.OptionNumber,
          quoteNumber: this.quoteModel.QuoteId,
          user: this.user,
          waitingPeriod: this.selectedWaitingPeriodCategory.Id
        };

        this.optionsService.selectOption(model).subscribe(response => {
          //sessionFactory.setSession('quoteId');   TODO ? set session to blank? need to check next page.
          this.router.navigate(['/mortgagee']);
        });
      }
      else if (effDate >= today && effDate < oneWeek) {
        this.effectiveDateOneWeek = true;
        this.showSpinner = false;
      }

      if (effDate < today) {
        this.pastEffDate = true;
        this.showSpinner = false;
      }

      if (this.riskAddressState == 'FL' && (this.selectedWaitingPeriodCategory.Id == 2 || this.selectedWaitingPeriodCategory.Id == 3) && this.rejectedDocuments) {
        // console.log(requestDateCorrectFormat)
        // console.log(effDate)
        // console.log(submitDatePlusSevenDays)
        // console.log(sevenDaysAgo)
        // console.log(requestDateCorrectFormat < today)
        // console.log(effDate >= requestDateCorrectFormat)
        // console.log(effDate <= submitDatePlusSevenDays)
        // console.log(effDate >= sevenDaysAgo)
        if(requestDateCorrectFormat < today && effDate >= requestDateCorrectFormat && effDate <= submitDatePlusSevenDays && effDate >= sevenDaysAgo){
          this.pastEffDate = false;
          this.showSpinner = true;

          let model = {
            effectiveDate: effDate,
            optionNumber: option.OptionNumber,
            quoteNumber: this.quoteModel.QuoteId,
            user: this.user,
            waitingPeriod: this.selectedWaitingPeriodCategory.Id
          };
  
          this.optionsService.selectOption(model).subscribe(response => {
            //sessionFactory.setSession('quoteId');   TODO ? set session to blank? need to check next page.
            this.router.navigate(['/mortgagee']);
          });
        }
      }
    };

    if ((this.selectedWaitingPeriodCategory.Id == 2 || this.selectedWaitingPeriodCategory.Id == 3) && effDate >= today) {
      Swal.fire({
        input: 'checkbox',
        inputPlaceholder:
          '<p style=\"text-align:left;\">I hereby acknowledge that I am requesting the waiver of the waiting period due to either a loan closing or the property is being reclassified by FEMA. As the agent, I will keep the supporting documentation on file that will support this request.</p>',
        confirmButtonText: 'Continue'
      }).then(function(result) {
        if (result.value) {
          continueQuote();
        }
      });
    } else if(this.riskAddressState == 'FL' && (this.selectedWaitingPeriodCategory.Id == 2 || this.selectedWaitingPeriodCategory.Id == 3) && this.rejectedDocuments){
      if(requestDateCorrectFormat < today && effDate >= requestDateCorrectFormat && effDate <= submitDatePlusSevenDays && effDate >= sevenDaysAgo){
        Swal.fire({
          input: 'checkbox',
          inputPlaceholder:
            '<p style=\"text-align:left;\">I hereby acknowledge that I am requesting the waiver of the waiting period due to either a loan closing or the property is being reclassified by FEMA. As the agent, I will keep the supporting documentation on file that will support this request.</p>',
          confirmButtonText: 'Continue'
        }).then(function(result) {
          if (result.value) {
            continueQuote();
          }
        });
      }
    } else {
      continueQuote();
    }
  }

  assignTaxes() {
    let effDate = new Date();

    if (this.effectiveDate != null) {
      effDate = new Date(this.effectiveDate.year, this.effectiveDate.month - 1, this.effectiveDate.day);
    }
    else {
      effDate.setDate(effDate.getDate() + 7);
    }

    let today = new Date();
    let december25 = new Date("December 25, 2020 00:00:00");
    let january01 = new Date("January 01, 2021 00:00:00");

    if (today >= december25) {
      return;
    }
    else {
      if (effDate >= january01) {
        this.visibleOptions.forEach(x => x.Taxes = x.Taxes2021);
      }
      else {
        this.visibleOptions.forEach(x => x.Taxes = x.Taxes2020);
      }
      this.visibleOptions.forEach(x => x.Total = (x.Premium) + (x.Fees) + (x.Taxes));
    }
  }

}
