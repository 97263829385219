export class Mortgagee {
  name1: string;
  name2: string;
  loanNumber: string;
  street1: string;
  street2: string;
  city: string;
  stateId: number;
  zip: string;
}
