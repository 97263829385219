import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class RedirectService {
  private arcWebUrl: string;

  constructor(private httpClient: HttpClient, @Inject('ARC_WEB_URL') arcWebUrl: string, @Inject('ARC_API_URL') private arcApiBaseUrl: string) {
    this.arcWebUrl = arcWebUrl;
  }

  xenithRedirect(policyNumber) {
    let refreshToken = sessionStorage.getItem("refreshToken");
    let exp = sessionStorage.getItem("token_expiration");
    let userToken = {
      ExpirationDate: exp,
      RefreshToken: refreshToken
    };
    let userObject = {
      user_permissions: sessionStorage.getItem('permissions'),
      user_id: sessionStorage.getItem('user_id'),
      user_fullname: sessionStorage.getItem('name'),
      agency: sessionStorage.getItem("user_agency"),
      user_roles: sessionStorage.getItem('user_roles'),
      user_token: userToken,
      token_expiration: exp,
      agentId: sessionStorage.getItem('agentId'),
      state_access: sessionStorage.getItem("state_access"),
      agencyId: sessionStorage.getItem("agencyId")
    }

    let userString = btoa(JSON.stringify(userObject));
    let src = "hiscox";
    let queryString = `u=${userString}&src=${src}&policyNumber=${policyNumber}`;
    let url = `${this.arcWebUrl}policy/floodSearch?${queryString}`;

    window.location.replace(url);
  }

  policyByQuoteNumber(quoteNumber): Observable<any> {
    return this.httpClient.post<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/${quoteNumber}/policy`, null);
  }
}
