import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class OptionsService {
  arcApiBaseUrl: string;

  constructor(private httpClient: HttpClient, @Inject('ARC_API_URL') arcApiBaseUrl: string) {
    this.arcApiBaseUrl = arcApiBaseUrl;
  }

  getRiskAddress(quoteNumber): Observable<any> {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/number/${quoteNumber}/riskAddress`);
  }

  getRatedQuote(quoteNumber, user): Observable<any> {
    return this.httpClient.post<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/${quoteNumber}`, user);
  }

  selectOption(option): Observable<any> {
    return this.httpClient.post<any>(this.arcApiBaseUrl + '/api/1000/hiscox/quote/option', option);
  }

  checkQuoteStage(quoteNumber): Observable<any> {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/${quoteNumber}/stage`);
  }

  printQuoteOptions(model): Observable<any> {
    return this.httpClient.post<any>(this.arcApiBaseUrl + '/api/1000/hiscox/quote/options/print', model);
  }

  GetRejectedDocumentValidation(quoteId): Observable<any> {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/PreBind/GetRejectedDocumentValidation/${quoteId}`);
  }

}
