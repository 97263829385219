import { Component, ViewChild, Inject, ElementRef } from '@angular/core';
import { Address } from '../shared/address.model';
import { DropdownService } from '../shared/dropdown.service';
import { ApplicationService } from "./application.service";
import { NgForm } from '@angular/forms';
import { IFemaFloodZoneDto } from "../shared/IFemaFloodZoneDto";
import { Application } from "../shared/application.model";
import { UserDto } from "../shared/userDto.model";
import { ICountyDto } from "../shared/ICountyDto";
import { Router, ActivatedRoute } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import Swal from 'sweetalert2';
import { MoratoriumMessageDTO } from '../models/moratorium-message-dto';
import { MoratoriumsCountyDTO } from '../models/moratoriums-county-dto';
import { MoratoriumManagementService } from '../moratorium-management/moratorium-management.service';
import { MatDialog } from '@angular/material';
import { MoratoriumWarningDialog } from '../shared/moratorium-warning-dialog/moratorium-warning-dialog';

@Component({
  selector: 'app-application-component',
  templateUrl: './application.component.html'
})

export class ApplicationComponent {
  @ViewChild('applicationForm', { static: false }) applicationForm: NgForm;
  @ViewChild('dwellTiv', { static: false }) DwellingInput: ElementRef;

  loading: boolean;
  maxDate: number;
  errors: string[];
  risk: Address;
  mailing: Address;
  selectedRiskState: any;
  physicalStates: any[];
  mailingStates: any[];
  insuredFirstName: string;
  insuredLastName: string;
  occupancies: any[];
  selectedOccupancy: any;
  yearBuilt: number;
  constructionType: any[];
  selectedConstructionType: any;
  propNumStories: number;
  squareFeet: number;
  dwellingTiv: number;
  dwellingTivPercentage: any[];
  selectedDwellingTivPercentage: any;
  personalPropertyTiv: number;
  propertyCostValueType: any[];
  selectedPropertyCostValueType: any;
  homeElevated: boolean;
  homeElevatedTip: boolean;
  overWaterTypes: any[];
  selectedOverWaterType: any;
  previousLosses: boolean;
  losses: any[];
  floodLossYear: number;
  floodLossValue: number;
  buttonClick: boolean;
  elevatedAfter: boolean;
  showFoundationTypeInfo: boolean;
  foundationTypes: any[];
  selectedFoundationTypeId: number;
  elevationHeight: number;
  garage: number;
  crawlspace: number;
  enclosure: number;
  basement: number;
  floodZones: IFemaFloodZoneDto[];
  selectedFloodZone: IFemaFloodZoneDto;
  selectedRiskCounty: ICountyDto;
  counties: ICountyDto[];
  filteredCounties: ICountyDto[];
  showCounty: boolean;
  showSpinner: boolean;
  moratoriumMessage: MoratoriumMessageDTO;
  disabledCounties: MoratoriumsCountyDTO[] = [];
  previousCountySelection: any;
  moratoriumWarningDialogRef;

  constructor(private dropdownService: DropdownService, private applicationService: ApplicationService, private router: Router, private route: ActivatedRoute, @Inject('ARC_WEB_URL') private arcWebUrl: string, private moratoriumManagementService: MoratoriumManagementService, public dialog: MatDialog ) {
    this.errors = [];
    this.risk = new Address();
    this.mailing = new Address();
    this.floodZones = new Array<IFemaFloodZoneDto>();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let user = params['u'];
      let token = sessionStorage.getItem('user_token');

      if (token != null || token != undefined) {
        this.init();
      } else if (user == undefined || user == null) {
        window.location.href = this.arcWebUrl;
      } else {
        this.decodeUser(user);
        let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
        this.router.navigateByUrl(url);
      }
    });
  }

  private init() {
    this.maxDate = new Date().getFullYear();
    this.risk.street = '';
    this.risk.city = '';
    this.risk.county = '';
    this.mailing.street = '';
    this.mailing.city = '';
    this.insuredFirstName = '';
    this.insuredLastName = '';
    this.losses = [];
    this.showFoundationTypeInfo = false;
    this.homeElevatedTip = false;
    this.populateDropdowns();
    this.applicationService.getStates().subscribe(response => this.getQuoteableStates(response));
    this.applicationService.getFloodZones().subscribe(response => this.getFloodZones(response));
    this.applicationService.getCounties().subscribe(response => this.getCounties(response));
    this.showCounty = true;
    this.showSpinner = false;
    this.moratoriumManagementService.getCounties().subscribe(response => this.getDisabledCounties(response));
    this.moratoriumManagementService.getMoratoriumMessage().subscribe(response => this.moratoriumMessage = response);
  }

  decodeUser(obj) {
    var userObject = JSON.parse(atob(obj));

    sessionStorage.setItem("user_id", userObject.userId);
    sessionStorage.setItem("name", userObject.name);
    sessionStorage.setItem("permissions", JSON.stringify(userObject.user_permissions));
    sessionStorage.setItem("refreshToken", userObject.refreshToken);
    sessionStorage.setItem("user_token", userObject.user_token);
    sessionStorage.setItem("state_access", userObject.state_access);
    sessionStorage.setItem("token_expiration", userObject.token_expiration);
    sessionStorage.setItem("user_agency", userObject.agency);
    sessionStorage.setItem('user_roles', JSON.stringify(userObject.user_roles));
    sessionStorage.setItem('agentId', userObject.agentId);
    sessionStorage.setItem('agencyId', userObject.agencyId);
  }

  private getQuoteableStates(response) {
    var requoteId = sessionStorage.getItem("requoteId");
    this.physicalStates = response;
    var importQuoteNum = sessionStorage.getItem("quote_id");
    // todo
    if (requoteId == null) {
      if (importQuoteNum === null) {
        //this.risk.stateId = this.physicalStates;
      }
    } else {
      this.requote(requoteId);            
    }
  }

  private getCounties(response) {
    this.counties = response;
    this.filteredCounties = response;
  }

  private getFloodZones(response) {
    this.floodZones = response.sort((a, b) => a.SortingNum - b.SortingNum);
  }

  private getDisabledCounties(response) {
    response.forEach(county => {
      if(county.FlgActive == 'false') {
        this.disabledCounties.push(county);
      }
    });
  }

  copyRiskAddress() {
    this.mailing.street = this.risk.street;
    this.mailing.city = this.risk.city;
    this.mailing.zip = this.risk.zip;

    if (this.selectedRiskState != null || this.selectedRiskState != undefined) {
      this.mailing.stateId = this.selectedRiskState.Id;
    }
  }

  private populateDropdowns() {
    this.mailingStates = this.dropdownService.getMailingStates();
    this.occupancies = this.dropdownService.getOccupancies();
    this.constructionType = this.dropdownService.getConstructionTypes();
    this.dwellingTivPercentage = this.dropdownService.getDwellingTivPercentages();
    this.propertyCostValueType = this.dropdownService.getPersonalPropertyCostValueTypes();
    this.overWaterTypes = this.dropdownService.getOverWaterTypes();
    this.foundationTypes = this.dropdownService.getFoundationTypes();

    this.selectedPropertyCostValueType = this.propertyCostValueType[1];
  }

  changeDwellingPercentValues() {
    switch (this.selectedOccupancy.Id) {
      case 1:
        this.dwellingTivPercentage = [
          { Value: '35', Text: '35%' },
          { Value: '40', Text: '40%' },
          { Value: '45', Text: '45%' },
          { Value: '50', Text: '50%' },
          { Value: '55', Text: '55%' },
          { Value: '60', Text: '60%' },
          { Value: '65', Text: '65%' },
          { Value: '70', Text: '70%' }
        ];
        this.personalPropertyTiv = null;
        this.selectedDwellingTivPercentage = this.dwellingTivPercentage[0];
        break;
      case 2: // secondary
      case 3: // seasonal
      case 4: // tenated
        this.dwellingTivPercentage = [
          { Value: '10', Text: '10%' },
          { Value: '15', Text: '15%' },
          { Value: '20', Text: '20%' },
          { Value: '25', Text: '25%' },
          { Value: '30', Text: '30%' },
          { Value: '35', Text: '35%' },
          { Value: '40', Text: '40%' },
          { Value: '45', Text: '45%' },
          { Value: '50', Text: '50%' },
          { Value: '55', Text: '55%' },
          { Value: '60', Text: '60%' },
          { Value: '65', Text: '65%' },
          { Value: '70', Text: '70%' }
        ];
        this.personalPropertyTiv = null;
        this.selectedDwellingTivPercentage = this.dwellingTivPercentage[0];
        break;
      case 5: // vacant
      case 6: // course of construction
      case 7: // vacant (renovation)
        this.dwellingTivPercentage = [
          { Value: '0', Text: '0%' }
        ];

        this.selectedDwellingTivPercentage = this.dwellingTivPercentage[0];
        this.personalPropertyTiv = 0;

        break;
    }

    this.changePersonalPropTiv();
  }

  changePersonalPropTiv() {
    if (this.dwellingTiv !== null && this.dwellingTiv !== undefined && this.selectedDwellingTivPercentage !== null && this.selectedDwellingTivPercentage !== undefined) {
      var ceilValue = Math.ceil(this.dwellingTiv * (this.selectedDwellingTivPercentage.Value / 100));
      var floorValue = Math.floor(this.dwellingTiv * (this.selectedDwellingTivPercentage.Value / 100));
      var checkValue = this.dwellingTiv * (this.selectedDwellingTivPercentage.Value / 100);

      if (this.selectedDwellingTivPercentage.Value === '70' && ceilValue > checkValue) {
        this.personalPropertyTiv = floorValue;
      }
      else {
        this.personalPropertyTiv = ceilValue;
      }
    }
    else {
      this.personalPropertyTiv = null;
    }
  }

  changeConstructionType(constructionType) {
   
    if (this.dwellingTiv !== null && this.dwellingTiv) {

      //this.applicationForm.controls['DwellingTiv'].markAsUntouched();
      //this.applicationForm.controls['DwellingTiv'].setErrors({ 'customMin': false });
      this.applicationForm.controls['DwellingTiv'].setErrors(null);

      if (constructionType === 5) {
        if (this.dwellingTiv < this.squareFeet * 120) {
          this.applicationForm.controls['DwellingTiv'].markAsTouched();
          this.applicationForm.controls['DwellingTiv'].setErrors({ 'customMin': true });
        }

      } else {
        if (this.dwellingTiv < this.squareFeet * 100) {
          this.applicationForm.controls['DwellingTiv'].markAsTouched();
          this.applicationForm.controls['DwellingTiv'].setErrors({ 'customMin': true });
        }
      }
    }
  }
  validateConstructionType(constructionType,dwellingValue,squareFeetValue) {
    //console.log(constructionType, dwellingValue, squareFeetValue);
    if (dwellingValue !== null && dwellingValue) {
            
      //this.applicationForm.controls['DwellingTiv'].markAsUntouched();
      this.applicationForm.controls['DwellingTiv'].setErrors(null);

      if (constructionType === 5) {
        if (dwellingValue < squareFeetValue * 120) {
          this.applicationForm.controls['DwellingTiv'].markAsTouched();
          this.applicationForm.controls['DwellingTiv'].setErrors({ 'customMin': true });
        }


      } else {
        if (dwellingValue < squareFeetValue * 100) {
          this.applicationForm.controls['DwellingTiv'].markAsTouched();
          this.applicationForm.controls['DwellingTiv'].setErrors({ 'customMin': true });
        }
      }
    }
  }
  changeHomeElevated() {
    this.selectedFoundationTypeId = null;
    this.elevationHeight = null;
    this.garage = null;
    this.crawlspace = null;
    this.enclosure = null;
    this.basement = null;

    if (this.homeElevated) {
      this.foundationTypes = [
        { Id: 1, Value: 'PiersPostsPiles', Text: 'Piers, Posts, Piles' },
        { Id: 2, Value: 'ReinforcedMasonryEtc', Text: 'Reinforced Masonry, Etc.' },
        { Id: 3, Value: 'ReinforcedShearWalls', Text: 'Reinforced Shear Walls' },
        { Id: 4, Value: 'SolidFoundationWalls', Text: 'Solid Foundation Walls' }
      ];
    } else {
      this.foundationTypes = [
        { Id: 5, Value: 'FoundationWall', Text: 'Foundation Wall' },
        { Id: 6, Value: 'SlabOnGrade', Text: 'Slab On Grade' },
        { Id: 7, Value: 'SlabOnFill', Text: 'Slab On Fill' }
      ];
    }
  }

  changeCrawlspace() {
    if (this.crawlspace != 3 && this.crawlspace !== null && this.crawlspace !== undefined) {
      this.enclosure = 3;
    }
  }

  changeEnclosure() {
    if (this.enclosure != 3 && this.enclosure !== null && this.enclosure !== undefined) {
      this.crawlspace = 3;
    }
  }

  resetLosses() {
    this.floodLossYear = null;
    this.floodLossValue = null;
    this.losses = [];
    this.elevatedAfter = null;
  }

  addLoss() {
    this.buttonClick = true;
    if (this.floodLossValue !== undefined && this.floodLossYear !== undefined && this.floodLossValue !== null && this.floodLossYear !== null) {
      var loss = {
        YearOfLoss: this.floodLossYear,
        ValueOfLoss: this.floodLossValue
      };

      this.losses.push(loss);
      this.floodLossValue = null;
      this.floodLossYear = null;
      this.applicationForm.form.markAsPristine();
      this.applicationForm.form.markAsUntouched();
      this.applicationForm.form.updateValueAndValidity();
      this.buttonClick = false;
    }
  }

  removeLoss(index) {
    this.losses.splice(index, 1);
  }

  toggleFoundationTypeInfo() {
    this.showFoundationTypeInfo = !this.showFoundationTypeInfo;
  }

  isDisabledCounty() {
    if (this.selectedRiskCounty == undefined) {
      return false;
    }

    return this.applicationService.isDisabledCounty(this.selectedRiskCounty.Id);
  }

  private requote(requoteId) {
    this.loading = true;

    let app = new Application();
    let user = new UserDto();

    this.applicationService.requote(requoteId).subscribe(response => {

      this.risk.street = response.RiskAddressStreet1;
      this.risk.city = response.RiskAddressCity;
      this.selectedRiskState = this.physicalStates.filter(x => x.Id === response.RiskAddressState)[0];
      this.changeCounties();
      this.selectedRiskCounty = this.filteredCounties.filter(x => x.CountyName === response.RiskAddressCounty)[0];
      this.risk.zip = response.RiskAddressZip;
      this.mailing.street = response.MailingAddressStreet1;
      this.mailing.city = response.MailingAddressCity;
      this.mailing.stateId = response.MailingAddressState;
      this.mailing.zip = response.MailingAddressZip;

      this.insuredFirstName = response.FirstName;
      this.insuredLastName = response.LastName;

      this.selectedOccupancy = this.occupancies.filter(x => x.Id === response.Occupancy)[0];
      this.yearBuilt = response.YearBuilt;
      this.selectedConstructionType = this.constructionType.filter(x => x.Id === response.Construction)[0];
      this.propNumStories = response.NoOfStories;
      this.squareFeet = response.FloorArea;
      this.validateConstructionType(this.constructionType.filter(x => x.Id === response.Construction)[0].Id, response.DwellingTiv, response.FloorArea);
      //console.log(this.selectedConstructionType);
      this.dwellingTiv = response.DwellingTiv;
      this.personalPropertyTiv = response.PersonalPropertyTiv;
      this.setDwellingTivPercentage();

      this.selectedPropertyCostValueType = this.propertyCostValueType.filter(x => x.Id === response.PersonalPropertyCostValueType)[0];

      this.homeElevated = Boolean(response.IsElevated);
      this.changeHomeElevated();

      this.selectedFoundationTypeId = response.FoundationType;
      this.elevationHeight = response.ElevationHeight;

      if (response.IsElevated) {
        this.basement = null;
        let elevationDetails = response.ElevationDetailDtos;
        let garage = elevationDetails.filter(x => x.ElevationTypeId === 1);

        if (garage.length > 0) {
          this.garage = garage[0].CompletionStatusId;
        } else {
          this.garage = 3;
        }

        let crawlspace = elevationDetails.filter(x => x.ElevationTypeId === 2);

        if (crawlspace.length > 0) {
          this.crawlspace = crawlspace[0].CompletionStatusId;
          this.enclosure = 3;
        } else {
          this.crawlspace = 3;
        }

        let enclosure = elevationDetails.filter(x => x.ElevationTypeId === 3);

        if (enclosure.length > 0) {
          this.enclosure = enclosure[0].CompletionStatusId;
          this.crawlspace = 3;
        } else {
          this.enclosure = 3;
        }
      } else {
        this.garage = null;
        this.crawlspace = null;
        this.enclosure = null;

        if (response.HasBasement) {
          this.basement = response.CompletionStatus;
        } else {
          this.basement = 3;
        }
      }

      if (response.PriorFloodLosses.length > 0) {
        this.previousLosses = true;
        response.PriorFloodLosses.forEach(loss => {
          this.losses.push(loss);
        });
        this.elevatedAfter = response.HouseElevatedAfterPriorFloodLoss;
      }
      else {
        this.previousLosses = false;
      }

      this.selectedOverWaterType = this.overWaterTypes.filter(x => x.Id === response.BuildingOverWater)[0];
      this.selectedFloodZone = this.floodZones.filter(x => x.Id === response.FemaFloodZoneId)[0];

      //todo Not sure if this is needed ??
      //this.changeDwellingPercentValues();

      this.loading = false;
    }, error => {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong',
        text: 'There was an issue pre-filling the applicants information'
      });

    });  

  }

  setDwellingTivPercentage() {
    if (this.dwellingTiv !== null && this.dwellingTiv !== undefined && this.personalPropertyTiv !== null && this.personalPropertyTiv !== undefined) {
      let percent = Math.floor((this.personalPropertyTiv / this.dwellingTiv) * 100);
      let TivPercentage;
      if (percent % 5 === 0 && percent >= 10 && percent <= 70) {
        TivPercentage = { Value: percent.toString(), Text: percent.toString() + "%" };
      } else if (percent === 69) {
        TivPercentage = { Value: "70", Text: "70%" };
      } else {
        TivPercentage = null;
      }
      this.selectedDwellingTivPercentage = this.dwellingTivPercentage.filter(x => x.Text === TivPercentage.Text)[0];
    }
  }
  getSquareFeetCost(constructionType):number {
    if (constructionType === 5)
      return 120;
    else
      return 100;
  }
  onSubmit(isValid) {
    this.showSpinner = true;

    if(!this.verifyDisabledCounty()) {
      isValid = false;
    }

    if (this.previousLosses && this.losses.length === 0) {
      isValid = false;
    }

    if (isValid) {
      this.loading = true;

      let app = new Application();
      let user = new UserDto();

      user.username = sessionStorage.getItem('user_agency');
      user.agencyNumber = sessionStorage.getItem('user_agency');
      user.userid = Number(sessionStorage.getItem("user_id"));
      user.agentid = Number(sessionStorage.getItem("agentId"));
      user.agencyid = Number(sessionStorage.getItem("agencyId"));

      app.user = user;
      app.riskAddressStreet1 = this.risk.street;
      app.riskAddressCity = this.risk.city;
      app.riskAddressState = this.selectedRiskState.Id;
      app.riskAddressZip = this.risk.zip;
      app.riskAddressCounty = this.selectedRiskCounty.CountyName;
      app.mailingAddressStreet1 = this.mailing.street;
      app.mailingAddressCity = this.mailing.city;
      app.mailingAddressState = this.mailing.stateId;
      app.mailingAddressZip = this.mailing.zip;
      app.firstName = this.insuredFirstName;
      app.lastName = this.insuredLastName;
      app.occupancy = this.selectedOccupancy.Id;
      app.yearBuilt = this.yearBuilt;
      app.construction = this.selectedConstructionType.Id;
      app.buildingPurpose = 1;
      app.noOfStories = this.propNumStories;
      app.floorArea = this.squareFeet;
      app.dwellingTiv = this.dwellingTiv;
      app.personalPropertyTiv = this.personalPropertyTiv;
      app.personalPropertyCostValueType = this.selectedPropertyCostValueType.Id;
      app.isElevated = this.homeElevated;
      app.foundationType = this.selectedFoundationTypeId;
      app.elevationHeight = this.elevationHeight;
      app.garage = this.garage != 3 && this.garage !== null && this.garage !== undefined;
      app.garageStatus = (this.garage != 3 && this.garage !== null && this.garage !== undefined) ? this.garage : null;
      app.crawlspace = this.crawlspace != 3 && this.crawlspace !== null && this.crawlspace !== undefined;
      app.crawlspaceStatus = (this.crawlspace != 3 && this.crawlspace !== null && this.crawlspace !== undefined)
        ? this.crawlspace
        : null;
      app.enclosure = this.enclosure != 3 && this.enclosure !== null && this.enclosure !== undefined;
      app.enclosureStatus = (this.enclosure != 3 && this.enclosure !== null && this.enclosure !== undefined)
        ? this.enclosure
        : null;
      app.hasBasement = this.homeElevated == true ? null : this.basement != 3 && this.basement !== null && this.basement !== undefined;
      app.completionStatus = this.basement != 3 && this.basement !== null && this.basement !== undefined
        ? this.basement
        : null;
      app.buildingOverWater = this.selectedOverWaterType.Id;
      app.houseElevatedAfterPriorFloodLoss = this.elevatedAfter;
      app.femaFloodZoneId = this.selectedFloodZone.Id;

      if (this.losses.length > 0) {
        app.priorFloodLosses = this.losses;
      }

      this.applicationService.rateQuote(app).subscribe(response => {
        if (response.Errors != null && response.Errors.length > 0) {
          this.errors = this.parseValidationErrors(response.Errors);

          window.scrollTo(0, 0);

          this.loading = false;
          this.showSpinner = false;
        } else {
          var quote = {
            quoteNumber: response.QuoteId
          };

          sessionStorage.setItem('quote', JSON.stringify(quote));
          this.router.navigate(['/options']);
        }
      });
    } else {
      this.showSpinner = false;
    }
  }

  parseValidationErrors(errors): any[] {
    var lstErrors = [];

    for (var i = 0; i < errors.length; i++) {
      var error = errors[i];

      // coastal TIV hotfix, US8813
      if (error.indexOf('twfg.warning.tiv') !== -1) {
        var messageArray = error.split(":");

        this.selectedDwellingTivPercentage = { Value: messageArray[1].toString(), Text: messageArray.toString() + "%" };
        this.changePersonalPropTiv();
        lstErrors.push(messageArray[messageArray.length - 1]);
      }
      else if (error.indexOf('request.') !== -1) {
        var messageArray = error.split(":");

        lstErrors.push(messageArray[messageArray.length - 1]);
      } else {
        lstErrors.push(error);
      }
    }

    return lstErrors;
  }

  resetForm() {
    this.applicationForm.resetForm();

    window.scrollTo(0, 0);
  }

  changeCounties() {
    if (this.selectedRiskState != null) {
      this.filteredCounties = this.counties.filter(element => element.StateId == this.selectedRiskState.Id);
      this.selectedRiskCounty = null;
      this.showCounty = this.selectedRiskState == 18 ? false : true;
    }
  }

  verifyDisabledCounty(): Boolean {
    if(this.selectedRiskCounty) {
        if(this.disabledCounties.findIndex(x => x.Id == this.selectedRiskCounty.Id) >= 0) {
          this.openMoratoriumWarningDialog();
          this.previousCountySelection = this.selectedRiskCounty;
          return false;
        }
      this.previousCountySelection = this.selectedRiskCounty;
    }
    return true;
  }

  openMoratoriumWarningDialog() {
    this.moratoriumWarningDialogRef = this.dialog.open(MoratoriumWarningDialog, {
      width: '30%',
      data: 'County is currently under a moratorium. Cannot bind this quote.'
    });
  }
}
