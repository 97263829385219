import { Component, Inject, Optional } from "@angular/core";
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";
import { MoratoriumMessageDTO } from "src/app/models/moratorium-message-dto";
import { MoratoriumManagementService } from "../../moratorium-management.service";

@Component({
  selector: 'moratorium-message-dialog',
  templateUrl: 'moratorium-message-dialog.html',
  styleUrls: ['./moratorium-message-dialog.css']
})
export class MoratoriumMessageDialog {
  moratoriumMessage = new MoratoriumMessageDTO;

  constructor(private _moratoriumManagementService: MoratoriumManagementService, private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<MoratoriumMessageDialog>, @Optional() @Inject(MAT_DIALOG_DATA) public data: string){}

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.afterClosed().subscribe(result => {
      this.moratoriumMessage.Message = result;
      this._moratoriumManagementService.createMoratoriumMessage(this.moratoriumMessage).subscribe(() => {
        this._snackBar.open('Moratorium message saved successfully', 'OK', {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 3000
        });
      });
    });
  }
}
