import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class RoleMapperService {
  mapRole(roles: string): string {
    let parsedRoles = JSON.parse(roles);
    let role = '';

    if (parsedRoles.indexOf('Hiscox Agent') > -1) {
      role = 'agent';
    } else if (parsedRoles.indexOf('Underwriting') > -1 || parsedRoles.indexOf('Underwriting Manager') > -1) {
      role = 'underwriter';
    }

    return role;
  }
}
