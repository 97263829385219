export class AdditionalInsured {
  typeId: number;
  firstName: string;
  lastName: string;
  entityName: string;
  street1: string;
  street2: string;
  city: string;
  stateId: number;
  zip: string;
}
