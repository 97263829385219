import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MoratoriumManagementService {

  constructor(private httpClient: HttpClient, @Inject('ARC_API_URL') private arcApiBaseUrl: string) {}

  getCounties(): Observable<any> {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/moratorium/counties`)
      .pipe(
        catchError(this.handleError<any>('getCounties', [])),
        map(response => {
          return response.map(county => {
            return {
              Id: county.Id,
              CountyName: county.CountyName,
              StateName: county.StateName,
              FlgActive: county.FlgActive.toString()
            }
          })
        })
      );
  }

  updateCountyActivity(updatedCounties): Observable<any> {
    return this.httpClient.put(this.arcApiBaseUrl + `/api/1000/hiscox/moratorium/counties`, JSON.parse(updatedCounties))
      .pipe(
        catchError(this.handleError<any>('updateCountyActivity', []))
      );
  }

  createMoratoriumMessage(message): Observable<any> {
    return this.httpClient.post(this.arcApiBaseUrl + `/api/1000/hiscox/moratorium/message`, message)
      .pipe(
        catchError(this.handleError<any>('createMoratoriumMessage', []))
      );
  }

  getMoratoriumMessage(): Observable<any> {
    return this.httpClient.get(this.arcApiBaseUrl + `/api/1000/hiscox/moratorium/message`)
      .pipe(
        catchError(this.handleError<any>('getMoratoriumMessage', []))
      );
  }

   /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
