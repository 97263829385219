import { Component, ViewChild } from "@angular/core";
import { MortgageeService } from "./mortgagee.service";
import { Mortgagee } from "../shared/mortgagee.model";
import { AdditionalInsured } from "../shared/additionalInsured.model";
import { IStateDto } from "../shared/IState";
import { Router, ActivatedRoute } from "@angular/router";
import { UserDto } from "../shared/userDto.model";
import { RoleMapperService } from "../shared/role-mapper.service";
import { AbstractControl, FormControl, NgForm, Validators } from "@angular/forms";
import { FileUploadDTO } from "../models/file-upload-dto";
import { FileType } from "../models/enums/file-type-enum";
import { DragAndDropFileComponent } from "../drag-and-drop-file/drag-and-drop-file.component";
import { getValueInRange } from "@ng-bootstrap/ng-bootstrap/util/util";
import { PreBindFileTypeEnum } from "../models/enums/pre-bind-file-type-enum";
import { PreBindStatusDTO } from "../models/pre-bind-status-dto";
import { PreBindSignStatusEnum } from "../models/enums/pre-bind-sign-status-enum";
import { DropDownDTO } from "../models/drop-down-dto";
import { FloridaFileDTO } from "../models/florida-file-dto";
import Swal from 'sweetalert2';
import { PreBindSign } from "../models/pre-bind-sign";
import { PreBindSignTypeEnum } from "../models/enums/pre-bind-sign-type-enum";

@Component({
  selector: 'app-mortgagee-component',
  templateUrl: './mortgagee.component.html',
  styleUrls: ['./mortgagee.component.scss']
})

export class MortgageeComponent {

  @ViewChild('aif', { static: false }) aiForm: NgForm;
  @ViewChild('dnd', { static: false }) dndComponent: DragAndDropFileComponent;

  user: UserDto;
  quoteId: any;
  quoteNum: string;
  clientInformation: string;
  riskAddress: string;
  mortgageesForQuote: any[];
  showErrorMessage: boolean;
  errorMessage: string;
  isExpired: boolean;
  toggle: string;
  quoteMortgagee: any;
  mortgagee: Mortgagee;
  tempMortgagee: Mortgagee;
  tempMortgagee2: Mortgagee;
  statesDropDown: IStateDto[];
  additionalInsuredTypeDropDown: any[];
  rowToEdit: number;
  aiRowToEdit: number;
  additionalInsured: any[];
  showSpinner: boolean;
  addAdditionalInsured: boolean;
  additionalInsuredNew: AdditionalInsured;

  //PRE BIND Fields
  allowedFiles: DropDownDTO[];
  floridaFiles: FloridaFileDTO[] = [];
  state: string = '';

  signByDocuSign: boolean = false;
  isFlorida: boolean = false;
  hasFiles: boolean = false;
  hasChanges: boolean = false;
  uploadFileSuccess = false;
  isQuoteApproved = false;
  preBindSignStatus: PreBindStatusDTO = null;
  currentPreBindSign: PreBindSign = null;
  insuredEmail: string = '';

  constructor(private mortgageeService: MortgageeService, private router: Router,  private route: ActivatedRoute, private roleMapper: RoleMapperService) {
    this.mortgagee = new Mortgagee();
    this.tempMortgagee = new Mortgagee();
    this.tempMortgagee2 = new Mortgagee();
    this.user = new UserDto();
    this.additionalInsured = [];
    this.addAdditionalInsured = false;
    this.additionalInsuredNew = new AdditionalInsured();
    this.additionalInsuredNew.typeId = 1;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let user = params['u'];

      if (user != undefined || user != null) {
        this.decodeUser(user);

        let quoteNumber = params['q'];
        var quote = {
          quoteNumber: Number(quoteNumber)
        };

        sessionStorage.setItem('quote', JSON.stringify(quote));

        let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
        this.router.navigateByUrl(url);
      }
    });

    this.allowedFiles = [
      { text: "Surplus Lines", value: PreBindFileTypeEnum.FloridaSurplusLinesForm },
      { text: "NFIP Acknowledgment", value: PreBindFileTypeEnum.FloridaNFIPForm }
    ];

    let quote = JSON.parse(sessionStorage.getItem('quote'));

    if (quote == undefined || quote == null) {
      this.router.navigate(['/application']);
    }
    this.populateDropDowns();

    this.user.username = sessionStorage.getItem('user_agency');
    this.user.agencyNumber = sessionStorage.getItem('user_agency');
    this.user.role = this.roleMapper.mapRole(sessionStorage.getItem('user_roles'));
    this.user.userid = Number(sessionStorage.getItem("user_id"));
    this.user.agentid = Number(sessionStorage.getItem("agentId"));
    this.user.agencyid = Number(sessionStorage.getItem("agencyId"));

    this.quoteNum = quote.quoteNumber;
    this.clientInformation = '';
    this.riskAddress = '';
    this.toggle = 'one';

    this.mortgageeService.getQuoteId(quote.quoteNumber).subscribe(response => {
      this.quoteId = response;
      this.getPreBindSign();
      this.downloadPreBindDocuments();
      this.isFloridaFormsApproved();
      this.getPreBindStatus();
    });

    this.mortgageeService.expiredCheck(quote.quoteNumber).subscribe(response => this.isExpired = response.Expired);
    this.mortgageeService.mortgageesByQuoteNumber(quote.quoteNumber).subscribe(response => this.getMortgageesForQuote(response));
    this.mortgageeService.getRatedQuote(quote.quoteNumber, this.user).subscribe(response => this.getRatedQuote(response));
    this.mortgageeService.getStates().subscribe(response => this.statesDropDown = response);
    this.mortgageeService.getRiskAddress(quote.quoteNumber).subscribe(response => this.setRiskAddress(response));
    this.mortgageeService.getAdditionalInsured(quote.quoteNumber).subscribe(response => this.additionalInsured = response);
  }

  isFloridaFormsApproved(){
    this.mortgageeService.isQuoteApproved(this.quoteId).subscribe((result) => {
      if(result != null && result == true){
        this.isQuoteApproved = true;
      }
      else{
        this.isQuoteApproved = false;
      }
    });
  }

  getPreBindStatus(){
    this.mortgageeService.getPreBindStatus(this.quoteId).subscribe((result) => {
      if(result){
        this.preBindSignStatus = result;
      }
    });
  }

  getPreBindSign(){
    this.mortgageeService.getPreBindSign(this.quoteId).subscribe((result) => {
      if(result){
        this.currentPreBindSign = result;
        this.insuredEmail = result.InsuredEmail;
        this.signByDocuSign = this.currentPreBindSign.SignTypeId == PreBindSignTypeEnum.DocuSign ? true : false;
      }
    });
  }

  toggleData(val) {
    this.toggle = val;
  }

  addMortgagee(valid) {
    if (valid) {
      if (this.toggle === 'two') {
        this.quoteMortgagee.flgPrimary = true;
      }

      let mortgageeObject = {
        QuoteNum: this.quoteNum,
        LoanNumber: this.mortgagee.loanNumber.trim(),
        Name1: this.mortgagee.name1.trim(),
        Name2: this.mortgagee.name2 ? this.mortgagee.name2.trim() : this.mortgagee.name2,
        Street1: this.mortgagee.street1.trim(),
        Street2: this.mortgagee.street2 ? this.mortgagee.street2.trim() : this.mortgagee.street2,
        City: this.mortgagee.city.trim(),
        StateId: this.mortgagee.stateId,
        Zip: this.mortgagee.zip.trim(),
        FlgPrimary: this.quoteMortgagee.flgPrimary,
        user: this.user
      };

      this.mortgageeService.createMortgagee(mortgageeObject).subscribe(response => {
        this.showSpinner = false;
        this.mortgageeService.mortgageesByQuoteNumber(this.quoteNum).subscribe(response => this.getMortgageesForQuote(response));
        this.rowToEdit = null;
      });
    }
  }

  getMortgageesForQuote(response) {
    this.mortgageesForQuote = response;

    if (response.length === 1) {
      this.toggle = 'three';
    } else if (response.length === 2) {
      this.toggle = 'five';
    } else {
      this.toggle = 'one';
    }

    this.mortgagee = new Mortgagee();
    this.showSpinner = false;
  }

  getRatedQuote(response) {
    this.clientInformation = response.ClientName;
  }

  setRiskAddress(response) {
    this.isFlorida = response.State == 'FL';
    this.state = response.State;
    this.riskAddress = response.Street1 + ((response.Street2 == null || response.Street2 == "") ? "" : (" " + response.Street2)) + " " + response.City + ", " + response.State + " " + response.Zip;
  }

  populateDropDowns() {
    this.quoteMortgagee = {
      enableSorting: true,
      columnDefs: [
        { name: 'Id', field: 'Id' },
        { name: 'loanNumber', field: 'LoanNumber' },
        { name: 'name1', field: 'Name1' },
        { name: 'name2', field: 'Name2' },
        { name: 'street1', field: 'Street1' },
        { name: 'street2', field: 'Street2' },
        { name: 'city', field: 'City' },
        { name: 'stateId', field: 'StateId' },
        { name: 'zip', field: 'Zip' },
        { name: 'flgPrimary', field: 'FlgPrimary' }
      ]
    }
    this.additionalInsuredTypeDropDown = [
      { Id: 1, Value: "Individual" },
      { Id: 3, Value: "HOA" },
      { Id: 2, Value: "Corporation" }
    ];
  }

  goToBilling() {
    this.router.navigate(['/billing']);
  }

  showEdit(index) {
    this.rowToEdit = index;
  }

  editAi(index, valid) {
    if (valid) {
      this.aiRowToEdit = index;
    }
  }

  removeMortgagee(mortgagee) {
    this.showSpinner = true;

    let mortgageeObject = {
      Id: mortgagee.Id,
      User: this.user
    };

    this.mortgageeService.removeMortgagee(mortgageeObject).subscribe(response => {
      this.mortgageeService.mortgageesByQuoteNumber(this.quoteNum).subscribe(response => {
        this.getMortgageesForQuote(response);
        this.showSpinner = false;
      });
    });
  }

  updateMortgagee(mortgagee) {
    this.showSpinner = true;
    mortgagee.QuoteNum = this.quoteNum;

    this.mortgageeService.updateMortgagee(mortgagee).subscribe(response => {
      this.showSpinner = false;

      if (response === false) {
        this.showSpinner = false;
        this.showErrorMessage = true;
        this.errorMessage = 'Only one mortgagee can be primary';
      } else if (response === true) {
        this.mortgageeService.mortgageesByQuoteNumber(this.quoteNum).subscribe(response => {
          this.getMortgageesForQuote(response);
          this.rowToEdit = null;
          this.showSpinner = false;
        });
      }
    });
  }

  mapAdditionalInsured(id) {
    if (id == 1) {
      return 'Individual';
    } else if (id == 2) {
      return 'Corporation';
    } else if (id == 3) {
      return 'HOA';
    }
  }

  addAdditionalInsuredEditableRow(valid) {
    if (valid) {

      let temp = {
        Id: 0,
        QuoteId: this.quoteId,
        FirstName: this.additionalInsuredNew.firstName,
        LastName: this.additionalInsuredNew.lastName,
        EntityName: this.additionalInsuredNew.entityName,
        AdditionalInsuredTypeId: this.additionalInsuredNew.typeId,
        AddressLine1: this.additionalInsuredNew.street1,
        AddressLine2: this.additionalInsuredNew.street2,
        City: this.additionalInsuredNew.city,
        StateId: this.additionalInsuredNew.stateId,
        ZipCode: this.additionalInsuredNew.zip
      };

      this.additionalInsured.push(temp);
      this.aiRowToEdit = this.additionalInsured.length - 1;
      this.addAdditionalInsured = false;
      this.additionalInsuredNew = new AdditionalInsured();
      this.additionalInsuredNew.typeId = 1;
      this.updateAdditionalInsured(valid);
    }
  }

  aiTypeChange(index: number, type: number) {
    switch (type) {
      case 1:
        if (index !== -1) {
          this.additionalInsured[index].EntityName = '';
        } else {
          this.additionalInsuredNew.entityName = '';
        }
        break;
      case 2:
      case 3:
        if (index !== -1) {
          this.additionalInsured[index].FirstName = '';
          this.additionalInsured[index].LastName = '';
        } else {
          this.additionalInsuredNew.firstName = '';
          this.additionalInsuredNew.lastName = '';
        }

        break;
    }
  }

  updateAdditionalInsured(valid) {
    if (valid) {
      this.additionalInsured[this.aiRowToEdit].QuoteId = this.quoteId;

      let model = this.additionalInsured[this.aiRowToEdit];
      model.user = this.user;

      this.mortgageeService.updateAdditionalInsured(model).subscribe(response => {
        this.mortgageeService.getAdditionalInsured(this.quoteNum).subscribe(ai => {
          this.aiForm.resetForm();
          this.additionalInsured = ai;
          this.aiRowToEdit = null;
        });
      });
    }
  }

  cancelAdditionalInsured() {
    if (this.additionalInsured[this.aiRowToEdit].Id == 0) {
      this.aiForm.resetForm();
      this.additionalInsured.splice(this.aiRowToEdit, 1);
    }
    else {
      this.mortgageeService.getAdditionalInsured(this.quoteNum).subscribe(ai => {
        this.aiForm.resetForm();
        this.additionalInsured = ai;
        this.aiRowToEdit = null;
      });
    }

    this.aiRowToEdit = null;
  }

  removeAdditionalInsured(index) {
    let id = this.additionalInsured[index].Id;
    this.mortgageeService.removeAdditionalInsured(id).subscribe(response => {
      this.aiForm.resetForm();
      this.additionalInsured.splice(index, 1);
    });
  }

  onFloridaFormsDropped(forms: FloridaFileDTO[]): void{
    if(this.hasFiles){
      this.hasChanges = true;
    }
    this.floridaFiles = forms;
  }

  isFloridaFormsValid(): boolean{
    if(this.state != 'FL'){
      return true;
    }
    else if(!this.isQuoteApproved){
      return false;
    }

    return true;
  }

  isFileTypeDuplicated(): boolean{
    for (const allowed of this.allowedFiles) {
      if(this.floridaFiles.filter(x => x.type == allowed.value).length > 1){
        return true;
      }
    }
    return false;
  }

  submitFiles(): void{

    if(this.hasFiles && this.hasChanges == false){
      return;
    }
    else if(this.floridaFiles.filter(x => x.type == PreBindFileTypeEnum.None).length > 0){
      Swal.fire({
        icon: 'error',
        showCloseButton: true,
        title: 'Error sending files',
        html: 'Unable to upload files without a type, please select one.',
        confirmButtonText: 'Ok'
      });
      return;
    }
    else if(this.isFileTypeDuplicated()){
      Swal.fire({
        icon: 'error',
        showCloseButton: true,
        title: 'Error sending files',
        html: 'Unable to upload files of the same type, please select again.',
        confirmButtonText: 'Ok'
      });
      return;
    }

    this.showSpinner =true;

    let surplusForm = {
      quoteId: this.quoteId,
      policyId: 0,
      user: this.user,
      fileName: 'Surplus_Lines.pdf',
      fileData: '',
      fileType: PreBindFileTypeEnum.FloridaSurplusLinesForm,
      docDescription: 'Signed Florida Surplus Lines'
    } as FileUploadDTO;

    let NFIPForm = {
      quoteId: this.quoteId,
      policyId: 0,
      user: this.user,
      fileName: 'NFIP_Acknowledgment.pdf',
      fileData: '',
      fileType: PreBindFileTypeEnum.FloridaNFIPForm,
      docDescription: 'Signed Florida NFIP'
    } as FileUploadDTO;

    this.getBase64(this.floridaFiles.find(x => x.type == PreBindFileTypeEnum.FloridaSurplusLinesForm).file).then((surplusBase64) => {
      surplusForm.fileData = surplusBase64 as string;
      this.getBase64(this.floridaFiles.find(x => x.type == PreBindFileTypeEnum.FloridaNFIPForm).file).then((NFIPBase64) => {
        NFIPForm.fileData = NFIPBase64 as string;
        const filesToUpload = new Array<FileUploadDTO>();
        filesToUpload.push(surplusForm, NFIPForm);
        this.mortgageeService.uploadFloridaFormsManuallySigned(filesToUpload).subscribe((result) =>{
          this.showSpinner = false;
          if(result){
            Swal.fire({
              icon: 'success',
              showCloseButton: true,
              title: 'Documents sent!',
              html: '<div style="font-size: 14px"><p align="justify">Your signed documents have been submitted to Underwriting for review. Please allow 24 business hours for a response.<br/><br/>' +
              'NOTE: <br/></div>' +
              '<div style="font-size: 10px" align="justify">1. Coverage has not been bound. If approved, you must access the quote from the Approved Quotes section and submit the application to Underwriting for final approval.<br/>' +
              '2. A successful quote submission does not guaranty binding of coverage. TWFG Underwriting retains final binding authority.<br/>' +
              '3. Coverage cannot be back-dated for any reason (this includes the 7-day waiting period).<br/><br/>' +
              'If you have any questions or need this review expedited, please contact our Underwriting team at (281) 367-3424 or underwriting@twfg.com. </p></div>',
              confirmButtonText: 'Ok'
            });
            this.uploadFileSuccess = true;
            this.dndComponent.clearFiles();
            this.getPreBindSign();
            this.downloadPreBindDocuments();
            this.getPreBindStatus();
            this.isFloridaFormsApproved();
            this.hasChanges = false;
          }
        },
        error => {
          this.showSpinner = false;
        });
      });
    });

  }

  downloadPreBindDocuments(): void{
    this.showSpinner = true;
    this.mortgageeService.getPreBindDocuments(this.quoteId).subscribe((response) => {
      this.showSpinner = false;
      if(response && response.length > 0){
        this.hasFiles = true;
        for(let file of response){
          const blobFileData = this.base64ToBlob(file.FileBase64, file.ContentType);
          this.floridaFiles.push({
            file: new File([blobFileData], file.FileName),
            type: file.FileType
          });
        }
        this.dndComponent.setFiles(this.floridaFiles);
      }else{
        this.floridaFiles = [];
        this.dndComponent.clearFiles();
      }
    }, error => {
      this.showSpinner = false;
    });
  }

  base64ToBlob(dataURI: string, contentType: string): Blob {
    const byteString = atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: contentType });
    return blob;
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  getPreBindStatusText(status: PreBindSignStatusEnum): any{
    switch(status){
      case PreBindSignStatusEnum.Approved:
        return {
          Text: "Approved",
          Color: "#15b800"
        }
      case PreBindSignStatusEnum.Rejected:
        return {
          Text: "Rejected",
          Color: "#e30000"
        }
      case PreBindSignStatusEnum.WaitingApproval:
        return {
          Text: "Waiting for approval",
          Color: "#e0ad02"
        }
      case PreBindSignStatusEnum.WaitingSignature:
        return {
          Text: "Waiting for signature",
          Color: "#e0ad02"
        }
      case PreBindSignStatusEnum.Completed:
        return {
          Text: "Completed",
          Color: "#15b800"
        }
      case PreBindSignStatusEnum.Failed:
        return {
          Text: "Failed",
          Color: "#e30000"
        }
    }
  }

  areFilesReadyToSubmit(){
    return this.floridaFiles.length >= 2 && this.floridaFiles.filter(x => x.type == PreBindFileTypeEnum.None).length <= 0;
  }

  canSubmitAgain(): boolean{
    if(this.preBindSignStatus == null){
      return true;
    }
    else if(this.preBindSignStatus.Status == PreBindSignStatusEnum.Approved ||
            this.preBindSignStatus.Status == PreBindSignStatusEnum.Completed ||
            this.preBindSignStatus.Status == PreBindSignStatusEnum.Failed){
      return false;
    }
  }

  isManuallyBlocked(){
    if(this.currentPreBindSign == null){
      return false;
    }
    else if(this.currentPreBindSign.SignTypeId == PreBindSignTypeEnum.DocuSign &&
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.Approved ||
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.Completed||
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.WaitingApproval ||
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.WaitingSignature){
      return true;
    }

    return false;
  }

  isDocuSignBlocked(){
    if(this.currentPreBindSign == null){
      return false;
    }
    else if(this.currentPreBindSign.SignTypeId == PreBindSignTypeEnum.Manually &&
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.Approved ||
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.Completed||
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.WaitingApproval ||
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.WaitingSignature){
      return true;
    }

    return false;
  }

  isDocuSignRequestButtonBlocked(){
    if(this.currentPreBindSign == null){
      return false;
    }
    else if(this.currentPreBindSign.SignTypeId == PreBindSignTypeEnum.DocuSign &&
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.Approved ||
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.Completed||
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.WaitingApproval ||
            this.currentPreBindSign.StatusId == PreBindSignStatusEnum.WaitingSignature){
      return true;
    }

    return false;
  }

  isInsuredEmailValid() : boolean{
    if(this.insuredEmail == null || this.insuredEmail == '' || this.insuredEmail.length <= 0){
      Swal.fire({
        icon: 'error',
        showCloseButton: true,
        title: 'Error validating email',
        html: 'Insured e-mail is required.',
        confirmButtonText: 'Ok'
      });
      return false;
    }

    let control = new FormControl(this.insuredEmail, Validators.email);

    if(control.errors){
      console.log(control.errors);
      Swal.fire({
        icon: 'error',
        showCloseButton: true,
        title: 'Error validating email',
        html: 'Insured e-mail is not in the right format.',
        confirmButtonText: 'Ok'
      });
      return false;
    }

    return true;
  }

  submitByDocuSign(){
    if(!this.isInsuredEmailValid()){
      return;
    }

    this.showSpinner = true;
    this.mortgageeService.signViaDocuSign(this.quoteId, this.user.userid, this.insuredEmail).subscribe((result) => {
      if(result && result == true){
        this.getPreBindSign();
        this.downloadPreBindDocuments();
        this.getPreBindStatus();
        this.isFloridaFormsApproved();
      }
      this.showSpinner = false;
    }, error => {
      this.showSpinner = false;
    });
  }

  decodeUser(obj) {
    var userObject = JSON.parse(atob(obj));

    sessionStorage.setItem("user_id", userObject.userId);
    sessionStorage.setItem("name", userObject.name);
    sessionStorage.setItem("permissions", JSON.stringify(userObject.user_permissions));
    sessionStorage.setItem("refreshToken", userObject.refreshToken);
    sessionStorage.setItem("user_token", userObject.user_token);
    sessionStorage.setItem("state_access", userObject.state_access);
    sessionStorage.setItem("token_expiration", userObject.token_expiration);
    sessionStorage.setItem("user_agency", userObject.agency);
    sessionStorage.setItem('user_roles', JSON.stringify(userObject.user_roles));
    sessionStorage.setItem('agentId', userObject.agentId);
    sessionStorage.setItem('agencyId', userObject.agencyId);
  }

  downloadFile(index: number){
    const file = this.floridaFiles[index];
    this.getBase64(file.file).then((result) => {
      var blobContent = this.convertBase64StringToBlob(result as string, "application/pdf");
      let url = window.URL.createObjectURL(blobContent);
      window.open(url);
    });
  }

  private convertBase64StringToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
