import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { ApplicationComponent } from './application/application.component';
import { CustomMinDirective } from "./validators/custom-min-validator.directive";
import { CustomMaxDirective } from "./validators/custom-max-validator.directive";
import { CustomQuotableStateDirective } from "./validators/custom-quotable-state-directive";
import { CustomUnquotableZipDirective } from "./validators/custom-unquotable-zip-directive";
import { OptionsComponent } from "./options/options.component";
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MortgageeComponent } from "./mortgagee/mortgagee.component";
import { BillingComponent } from "./billing/billing.component";
import { CustomMatchingDirective } from "./validators/custom-matching-directive";
import { CustomRoutingMatchDirective } from "./validators/custom-routing-match-directive";
import { AuthInterceptor } from "./shared/jwt.interceptor";
import { CustomNgbDateFormat } from "./shared/Custom-NgbDateParserFormat";
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { DragAndDropFileComponent } from './drag-and-drop-file/drag-and-drop-file.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MoratoriumManagementComponent } from './moratorium-management/moratorium-management.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, MatRadioModule, MatDialogModule, MatSnackBarModule } from '@angular/material';
import { MoratoriumMessageDialog } from './moratorium-management/dialogs/moratorium-message-dialog/moratorium-message-dialog';
import { MoratoriumWarningDialog } from './shared/moratorium-warning-dialog/moratorium-warning-dialog';


@NgModule({
  declarations: [
    AppComponent,
    ApplicationComponent,
    OptionsComponent,
    MortgageeComponent,
    BillingComponent,
    CustomMinDirective,
    CustomMaxDirective,
    CustomQuotableStateDirective,
    CustomUnquotableZipDirective,
    CustomMatchingDirective,
    CustomRoutingMatchDirective,
    DragAndDropDirective,
    ProgressBarComponent,
    DragAndDropFileComponent,
    MoratoriumManagementComponent,
    MoratoriumMessageDialog,
    MoratoriumWarningDialog,
  ],
  entryComponents: [MoratoriumMessageDialog, MoratoriumWarningDialog],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgbModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'application', pathMatch: 'full' },
      { path: 'application', component: ApplicationComponent },
      { path: 'options', component: OptionsComponent },
      { path: 'mortgagee', component: MortgageeComponent },
      { path: 'billing', component: BillingComponent },
      { path: 'moratorium-management', component: MoratoriumManagementComponent },
    ]),
    NoopAnimationsModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateFormat }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
