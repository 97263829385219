import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CustomNgbDateFormat extends NgbDateParserFormatter {
  constructor() {
    super();
  }

  format(date: NgbDateStruct): string {
    if (date === null || date === undefined || date.year === null || date.month === null || date.day === null) {
      return '';
    }

    return `${String(date.month)}/${String(date.day)}/${String(date.year)}`;
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }

    let result: NgbDateStruct = {
      year: +value.split('/')[2],
      month: +value.split('/')[0],
      day: +value.split('/')[1]
    };

    return result;
  }
}
