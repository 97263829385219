import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class BillingService {

  private DisabledCounties: string[] = [
    //"Acadia Parish",
    //"Ascension Parish",
    //"Assumption Parish",
    //"Calcasieu Parish",
    //"Cameron Parish",
    //"East Baton Rouge Parish",
    //"East Feliciana Parish",
    //"Iberville Parish",
    //"Jefferson Davis Parish",
    //"Jefferson Parish",
    //"Lafayette Parish",
    //"Lafourche Parish",
    //"Livingston Parish",
    //"Orleans Parish",
    //"Plaquemines Parish",
    //"Pointe Coupee Parish",
    //"St. Bernard Parish",
    //"St. Charles Parish",
    //"St. Helena Parish",
    //"St. James Parish",
    //"St. John the Baptist Parish",
    //"St. Landry Parish",
    //"St. Martin Parish",
    //"St. Mary Parish",
    //"St. Tammany Parish",
    //"Tangipahoa Parish",
    //"Terrebonne Parish",
    //"Vermilion Parish",
    //"Washington Parish",
    //"West Baton Rouge Parish",
    //"West Feliciana Parish"
  ];

  constructor(private httpClient: HttpClient, @Inject('ARC_API_URL') private arcApiBaseUrl: string) { }

  getRiskAddress(quoteNumber): Observable<any> {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/number/${quoteNumber}/riskAddress`);
  }

  checkQuoteStage(quoteNumber): Observable<any> {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/${quoteNumber}/stage`);
  }

  isQuoteExpired(quoteNumber): Observable<any> {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/number/${quoteNumber}/expired`);
  }

  getBillingInformation(quoteNumber, user): Observable<any> {
    return this.httpClient.post<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/${quoteNumber}/billing`, user);
  }

  getMortgageesForQuote(quoteNumber) {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/mortgagee/quote/${quoteNumber}`);
  }

  getRatedQuote(quoteNumber, user): Observable<any> {
    return this.httpClient.post<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/${quoteNumber}`, user);
  };

  removeQuoteOption(quoteNumber) {
    return this.httpClient.post<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/${quoteNumber}/removeOption`, null);
  }

  processAch(achModel) {
    return this.httpClient.post<any>(this.arcApiBaseUrl + `/api/1000/hiscox/ach/process`, achModel);
  }

  bindQuote(bindObject): Observable<any> {
    return this.httpClient.post<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/bind`, bindObject);
  }

  isDisabledCounty(county: string) {
    return this.DisabledCounties.indexOf(county) !== -1;
  }

  getCountyMoratoriumStatus(quoteNumber) {
    return this.httpClient.get<any>(this.arcApiBaseUrl + `/api/1000/hiscox/quote/${quoteNumber}/county/status`);
  }
}
