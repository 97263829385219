import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DropdownService {

  getMailingStates() {
    return [
      { Id: 1, Code: 'AL' },
      { Id: 2, Code: 'AK' },
      { Id: 3, Code: 'AZ' },
      { Id: 4, Code: 'AR' },
      { Id: 5, Code: 'CA' },
      { Id: 6, Code: 'CO' },
      { Id: 7, Code: 'CT' },
      { Id: 8, Code: 'DE' },
      { Id: 9, Code: 'FL' },
      { Id: 10, Code: 'GA' },
      { Id: 11, Code: 'HI' },
      { Id: 12, Code: 'ID' },
      { Id: 13, Code: 'IL' },
      { Id: 14, Code: 'IN' },
      { Id: 15, Code: 'IA' },
      { Id: 16, Code: 'KS' },
      { Id: 17, Code: 'KY' },
      { Id: 18, Code: 'LA' },
      { Id: 19, Code: 'ME' },
      { Id: 20, Code: 'MD' },
      { Id: 21, Code: 'MA' },
      { Id: 22, Code: 'MI' },
      { Id: 23, Code: 'MN' },
      { Id: 24, Code: 'MS' },
      { Id: 25, Code: 'MO' },
      { Id: 26, Code: 'MT' },
      { Id: 27, Code: 'NE' },
      { Id: 28, Code: 'NV' },
      { Id: 29, Code: 'NH' },
      { Id: 30, Code: 'NJ' },
      { Id: 31, Code: 'NM' },
      { Id: 32, Code: 'NY' },
      { Id: 33, Code: 'NC' },
      { Id: 34, Code: 'ND' },
      { Id: 35, Code: 'OH' },
      { Id: 36, Code: 'OK' },
      { Id: 37, Code: 'OR' },
      { Id: 38, Code: 'PA' },
      { Id: 39, Code: 'RI' },
      { Id: 40, Code: 'SC' },
      { Id: 41, Code: 'SD' },
      { Id: 42, Code: 'TN' },
      { Id: 43, Code: 'TX' },
      { Id: 44, Code: 'UT' },
      { Id: 45, Code: 'VT' },
      { Id: 46, Code: 'VA' },
      { Id: 47, Code: 'WA' },
      { Id: 48, Code: 'WV' },
      { Id: 49, Code: 'WI' },
      { Id: 50, Code: 'WY' },
      { Id: 51, Code: 'DC' }
    ];
  }

  getOccupancies(): any[] {
    return [
      { Id: 1, Value: 'Primary', Text: 'Primary' },
      { Id: 2, Value: 'Secondary', Text: 'Secondary' },
      { Id: 3, Value: 'Seasonal', Text: 'Seasonal' },
      { Id: 4, Value: 'Tenanted', Text: 'Tenanted' },
      { Id: 5, Value: 'Vacant', Text: 'Vacant' },
      { Id: 6, Value: 'Coc', Text: 'Course of Construction' },
      { Id: 7, Value: 'VacantRenovation', Text: 'Vacant (Renovation)' }
    ];
  }

  getConstructionTypes(): any[] {
    return [
      { Id: 1, Value: 'BrickVeneer', Text: 'Brick Veneer' },
      { Id: 2, Value: 'EIFS', Text: 'EIFS' },
      { Id: 3, Value: 'Frame', Text: 'Frame' },
      { Id: 4, Value: 'Log', Text: 'Log' },
      { Id: 5, Value: 'Masonry', Text: 'Masonry' },
      { Id: 6, Value: 'Stucco', Text: 'Stucco' },
      { Id: 7, Value: 'Asbestos', Text: 'Asbestos' }
    ];
  }

  getDwellingTivPercentages() {
    return [
      { Id: 1, Value: '10', Text: '10%' },
      { Id: 2, Value: '15', Text: '15%' },
      { Id: 3, Value: '20', Text: '20%' },
      { Id: 4, Value: '25', Text: '25%' },
      { Id: 5, Value: '30', Text: '30%' },
      { Id: 6, Value: '35', Text: '35%' },
      { Id: 7, Value: '40', Text: '40%' },
      { Id: 8, Value: '45', Text: '45%' },
      { Id: 9, Value: '50', Text: '50%' },
      { Id: 10, Value: '55', Text: '55%' },
      { Id: 11, Value: '60', Text: '60%' },
      { Id: 12, Value: '65', Text: '65%' },
      { Id: 13, Value: '70', Text: '70%' }
    ];
  }

  getPersonalPropertyCostValueTypes() {
    return [
      { Id: 1, Value: 'ActualCostValue', Text: 'Actual Cost Value' },
      { Id: 2, Value: 'ReplacementCostValue', Text: 'Replacement Cost Value' }
    ];
  }

  getFoundationTypes() {
    return [
      { Id: 1, Value: 'PiersPostsPiles', Text: 'Piers, Posts, Piles' },
      { Id: 2, Value: 'ReinforcedMasonryEtc', Text: 'Reinforced Masonry, Etc.' },
      { Id: 3, Value: 'ReinforcedShearWalls', Text: 'Reinforced Shear Walls' },
      { Id: 4, Value: 'SolidFoundationWalls', Text: 'Solid Foundation Walls' },
      { Id: 5, Value: 'FoundationWall', Text: 'Foundation Wall' },
      { Id: 6, Value: 'SlabOnGrade', Text: 'Slab On Grade' },
      { Id: 7, Value: 'SlabOnFill', Text: 'Slab On Fill' }
    ];
  }

  getOverWaterTypes() {
    return [
      { Id: 1, Value: 'No', Text: 'No' },
      { Id: 2, Value: 'Partially', Text: 'Partially' },
      { Id: 3, Value: 'Entirely', Text: 'Entirely' }
    ];
  }

  getWaitingPeriodTypes() {
    return [
      { Id: 1, Text: '7-Day Waiting Period'},
      { Id: 2, Text: 'Loan Closing - No Wait'},
      { Id: 3, Text: 'Reclassified (within last 60 days) - No Wait'}
    ];
  }
}
