import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IStateDto } from '../shared/IState';
import { IFemaFloodZoneDto } from '../shared/IFemaFloodZoneDto';
import { ICountyDto } from '../shared/ICountyDto';
import { Application } from "../shared/application.model";

@Injectable({
  providedIn: 'root'
})

export class ApplicationService {
  arcApiBaseUrl: string;
  private DisabledCounties: number[] = [ 
    //26, 27, //Acadia
    //1, 29, //Ascension
    //2, 30, //Assumption
    //3, 33, //Calcasieu
    //4, 34, //Cameron
    //5, 37, //East Baton Rouge
    //38, //East Feliciana
    //8, 41, //Iberville
    //9, 42, //Jefferson
    //10, 43, //Jefferson Davis
    //11, 44, //Lafayette
    //12, 45, //Lafourche
    //13, 46, //Livingston
    //14, 47, //Orleans
    //15, 48, //Plaquemines
    //49, //Pointe Coupee
    //16, 52, //St. Bernard
    //17, 53, //St. Charles
    //54, //St. Helena
    //18, 55, //st. James
    //19, 56, //st. John the Baptist
    //57, //st. Landry
    //20, 58, //st. Martin
    //21, 59, //st. Mary
    //22, 60, //st. Tammany
    //23, 61, //st. Tangipahoa
    //24, 62, //st. Terrebonne
    //25, 63, //Vermilion
    //65, //Washington
    //66, //West Baton Rouge
    //67, //West Feliciana
  ];

  constructor(private httpClient: HttpClient, @Inject('ARC_API_URL') arcApiBaseUrl: string) {
    this.arcApiBaseUrl = arcApiBaseUrl;
  }

  getStates(): Observable<IStateDto[]> {
    var permStates = sessionStorage.getItem('state_access');
    var model = { PermStates: permStates, User: null };

    return this.httpClient.post<IStateDto[]>(this.arcApiBaseUrl + '/api/1000/hiscox/states', model);
  }

  getFloodZones(): Observable<IFemaFloodZoneDto[]> {
    return this.httpClient.get<IFemaFloodZoneDto[]>(this.arcApiBaseUrl + '/api/1000/hiscox/floodzones');
  }

  rateQuote(app: Application): Observable<any>{
    return this.httpClient.post<any>(this.arcApiBaseUrl + '/api/1000/hiscox/quote', app);
  }

  getCounties(): Observable<ICountyDto[]> {
    return this.httpClient.get<ICountyDto[]>(this.arcApiBaseUrl + '/api/1000/hiscox/counties');
  }

  requote(requoteId: string): Observable<any> {
    return this.httpClient.get<any>(this.arcApiBaseUrl + '/api/1000/hiscox/quote/getapplication/' + requoteId);
  }

  isDisabledCounty(countyId: number) {
    return this.DisabledCounties.indexOf(countyId) !== -1;
  }
}
