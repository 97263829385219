import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MoratoriumsCountyDTO } from '../models/moratoriums-county-dto';
import { MatCheckboxChange, MatDialog, MatRadioGroup, MatSnackBar } from '@angular/material';
import { MoratoriumManagementService } from './moratorium-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDto } from '../shared/userDto.model';
import { MoratoriumMessageDialog } from './dialogs/moratorium-message-dialog/moratorium-message-dialog';
import { Location } from '@angular/common';
import { MoratoriumMessageDTO } from '../models/moratorium-message-dto';

@Component({
  selector: 'app-moratorium-management',
  templateUrl: './moratorium-management.component.html',
  styleUrls: ['./moratorium-management.component.css']
})
export class MoratoriumManagementComponent implements OnInit {
  displayedColumns: string[] = ['CountyName', 'StateName', 'flgActive'];
  dataSource: MatTableDataSource<MoratoriumsCountyDTO>;
  selection = new SelectionModel<MoratoriumsCountyDTO>(true, []);
  filterValues: any = {};
  county: MoratoriumsCountyDTO;
  updatedCounties: MoratoriumsCountyDTO[] = [];
  user: UserDto;
  moratoriumMessage: MoratoriumMessageDTO;
  activityFilter: string = 'all';
  countyName: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private _moratoriumManagementService: MoratoriumManagementService, private router: Router, private route: ActivatedRoute, @Inject('ARC_WEB_URL') private arcWebUrl: string, public dialog: MatDialog, private _snackBar: MatSnackBar, public _location: Location) {
    this.user = new UserDto();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let user = params['u'];
      let token = sessionStorage.getItem('user_token');

      if (token != null || token != undefined) {
        this.init();
      } else if (user == undefined || user == null) {
        window.location.href = this.arcWebUrl;
      } else {
        this.decodeUser(user);
        let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
        this.router.navigateByUrl(url);
      }
    });

    this._moratoriumManagementService.getMoratoriumMessage().subscribe(response => {
      if(response != null) {
        this.moratoriumMessage = response
      }
      else {
        this.moratoriumMessage = new MoratoriumMessageDTO();
      }
    });

  }

  private init() {
    this._moratoriumManagementService.getCounties()
      .subscribe(response => this.getCounties(response));
  }

  // Session authentication method
  decodeUser(obj) {
    var userObject = JSON.parse(atob(obj));

    sessionStorage.setItem("user_id", userObject.userId);
    sessionStorage.setItem("name", userObject.name);
    sessionStorage.setItem("permissions", JSON.stringify(userObject.user_permissions));
    sessionStorage.setItem("refreshToken", userObject.refreshToken);
    sessionStorage.setItem("user_token", userObject.user_token);
    sessionStorage.setItem("state_access", userObject.state_access);
    sessionStorage.setItem("token_expiration", userObject.token_expiration);
    sessionStorage.setItem("user_agency", userObject.agency);
    sessionStorage.setItem('user_roles', JSON.stringify(userObject.user_roles));
    sessionStorage.setItem('agentId', userObject.agentId);
    sessionStorage.setItem('agencyId', userObject.agencyId);
  }

  // Data manipulation methods
  getCounties(response) {   
    this.dataSource = new MatTableDataSource(response)
    this.dataSource.paginator = this.paginator;

    // Check the checkboxes of the counties that are active
    this.dataSource.data.forEach(row => {
      if(row.FlgActive == 'true') {
        this.selection.select(row);
      }
    });
  }

  updateCounties() {   
    this._moratoriumManagementService.updateCountyActivity(JSON.stringify(this.updatedCounties))
      .subscribe(response => this.openSubmitedSnackBar());
    this.updatedCounties = [] as MoratoriumsCountyDTO[];
  }

  addToUpdatedCounties(row: MoratoriumsCountyDTO, event: MatCheckboxChange) {   
    var newRow = row;
    event.checked ? newRow.FlgActive = 'true' : newRow.FlgActive = 'false';
    if(!this.updatedCounties.includes(row)) {
      this.updatedCounties.push(row);
    } else {
      this.updatedCounties[this.updatedCounties.indexOf(row)] = newRow;
    }
  }

  // Filter methods
  applyFilterCounty(column: string, filterValue: string) {    
    this.dataSource.filterPredicate = ((data: MoratoriumsCountyDTO, filter: string): boolean => {
      const filterValues = JSON.parse(filter);

      return (this.countyName ? data.CountyName.trim().toLowerCase().indexOf(filterValues.county) !== -1 : true) &&
        (this.activityFilter == 'all' ? true : data.FlgActive.trim().toLowerCase() == filterValues.active);
    });

    this.filterValues[column] = filterValue;
    this.dataSource.filter = JSON.stringify(this.filterValues);

    if (this.dataSource.filteredData.length > 0) {
      this.dataSource.filteredData.forEach(row => {
        if (this.filterValues['active'] == 'true') {
          this.selection.select(row);
         }
        else if (this.filterValues['active'] == 'false') {
          this.selection.deselect(row);
        }
        else {
          if (row.FlgActive == 'true')
            this.selection.select(row);
          else
            this.selection.deselect(row);
        }

      });

    }

    if (this.updatedCounties.length > 0) {
      this.updatedCounties.forEach(row => {
        if (this.filterValues['active'] == 'true') {
          this.selection.select(row);
        }
        else if (this.filterValues['active'] == 'false') {
          this.selection.deselect(row);
       }
      });
    }
  }

  // Dialog methods
  openMoratoriumMessageDialog() {  
    const dialogRef = this.dialog.open(MoratoriumMessageDialog, {
      width: '30%',
      data: this.moratoriumMessage.Message
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined) {       
        this.moratoriumMessage.Message = result;
      }
    });
  }

  // SnackBar method
  openSubmitedSnackBar() {
    let snackbar = this._snackBar.open('The status of the counties were updated', 'OK', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 3000
    });
  }
}
