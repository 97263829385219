import { Component, Inject } from "@angular/core";
import { BillingService } from "./billing.service";
import { Router } from "@angular/router";
import { UserDto } from "../shared/userDto.model";
import { RoleMapperService } from "../shared/role-mapper.service";
import { RedirectService } from "../shared/redirect.service";
import { MoratoriumManagementService } from "../moratorium-management/moratorium-management.service";
import { MatDialog } from "@angular/material";
import { MoratoriumWarningDialog } from "../shared/moratorium-warning-dialog/moratorium-warning-dialog";

@Component({
  selector: 'app-billing-component',
  templateUrl: './billing.component.html'
})

export class BillingComponent {
  isExpired: boolean;
  isPremier: boolean;
  quoteNum: string;
  quoteId: any;
  clientInformation: string;
  riskAddress: string;
  billingInformation: any;
  billingType: any;
  billingTypes: any[];
  mortgageesForQuote: any[];
  achInformation: any;
  agencyNumber: string;
  pastEffective: boolean;
  effectiveDateOneWeek: boolean;
  role: string;
  achErrors: string;
  flgPrimaryMort: boolean;
  achSubmitted: boolean;
  user: any;
  showSpinner: boolean;
  disabledCounty: boolean = false;
  moratoriumWarningDialogRef;
  isQuoteCountyDisabled: boolean;

  constructor(private billingService: BillingService, private router: Router, private roleMapper: RoleMapperService, @Inject('ARC_WEB_URL') private arcWebUrl: string, private redirectService: RedirectService, public dialog: MatDialog ) {

  }

  ngOnInit() {
    let quote = JSON.parse(sessionStorage.getItem('quote'));

    if (quote === null || quote == undefined) {
      this.router.navigate(['/application']);
    } else {
      this.isExpired = false;
      this.isPremier = false;
      this.clientInformation = '';
      this.riskAddress = '';
      this.billingInformation = {};
      this.billingTypes = ["Mortgagee", "ACH"];
      this.mortgageesForQuote = [];
      this.achInformation = [];
      this.agencyNumber = '';
      this.achErrors = '';
      this.achSubmitted = false;

      let user = new UserDto();

      user.username = sessionStorage.getItem('user_agency');
      user.agencyNumber = sessionStorage.getItem('user_agency');
      user.userid = Number(sessionStorage.getItem("user_id"));
      user.agentid = Number(sessionStorage.getItem("agentId"));
      user.agencyid = Number(sessionStorage.getItem("agencyId"));
      this.role = this.roleMapper.mapRole(sessionStorage.getItem('user_roles'));
      user.role = this.role;

      this.user = user;

      this.quoteNum = quote.quoteNumber;
      this.billingService.checkQuoteStage(this.quoteNum).subscribe(response => this.checkQuoteStage(response));
      this.billingService.isQuoteExpired(this.quoteNum).subscribe(response => {
        this.isExpired = response.Expired;
        this.pastEffective = response.PastEffective;
      });
      this.billingService.getBillingInformation(this.quoteNum, user).subscribe(response => this.getBillingInformation(response));
      this.billingService.getMortgageesForQuote(this.quoteNum).subscribe(response => this.getMortgagees(response));
      this.billingService.getRatedQuote(this.quoteNum, user).subscribe(response => { this.quoteId = response.QuoteId });
      this.billingService.getRiskAddress(this.quoteNum).subscribe(response => this.setRiskAddress(response));
      this.billingService.getCountyMoratoriumStatus(this.quoteNum).subscribe(response => this.isQuoteCountyDisabled = response);
    }
  }

  getMortgagees(response) {
    this.mortgageesForQuote = response;
    let flgPrimary = false;

    this.mortgageesForQuote.forEach(function(mort) {
      if (mort.FlgPrimary) {
        flgPrimary = true;
      }
    });

    this.flgPrimaryMort = flgPrimary;
  }

  getBillingInformation(response) {
    this.billingInformation = response;
    this.role = response.User.Role;
    this.agencyNumber = response.User.AgencyNumber;
    this.clientInformation = response.Quote.ClientName;

    var today = new Date();
    var oneWeek = new Date();
    oneWeek.setDate(oneWeek.getDate() + 7);

    oneWeek.setHours(0);
    oneWeek.setMinutes(0);
    oneWeek.setSeconds(0);
    oneWeek.setMilliseconds(0);

    var effDate = new Date(this.billingInformation.EffectiveDate);

    effDate.setHours(0);
    effDate.setMinutes(0);
    effDate.setSeconds(0);
    effDate.setMilliseconds(0);

    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    if (response.Quote.WaitingPeriodType == 2 || response.Quote.WaitingPeriodType == 3 || effDate >= oneWeek) {
      this.effectiveDateOneWeek = false;
    }
    else {
      this.effectiveDateOneWeek = true;
    }
    this.isPremier = this.billingInformation.Option.indexOf('Premier') > -1;
  }

  checkQuoteStage(response) {
    if (response != '/billing') {
      if (response == '/policy') {
        this.redirectService.policyByQuoteNumber(this.quoteNum).subscribe(response => {
          this.redirectService.xenithRedirect(response);
        });
      } else {
        this.router.navigate([response]);
      }
    }
  }

  reQuote() {
    sessionStorage.setItem("requoteId", JSON.stringify(this.quoteId));
    this.router.navigate(['/application']);
  }

  removeQuoteOption() {
    this.billingService.removeQuoteOption(this.quoteNum).subscribe(response => {
      this.router.navigate(['/options']);
    });
  }

  showBillingType(billingType): boolean {
    return billingType == this.billingType;
  }

  updateMortgagee() {
    this.router.navigate(['/mortgagee']);
  }

  bindQuote(isValid) {
    let billingValue = 0;

    if(this.isQuoteCountyDisabled == false) {
      this.openMoratoriumWarningDialog();
      isValid = false;
    }

    if(isValid) {
      switch (this.billingType) {
        case 'ACH':
          this.achSubmitted = true;
          billingValue = 1;
          break;
        case 'Mortgagee':
          billingValue = 2;
          break;
      }

      var bindObject = {
        user: this.user,
        quoteId: this.quoteNum,
        paymentType: billingValue
      };

      this.showSpinner = true;

      switch (billingValue) {
        case 1:
          var achModel = {
            Amount: this.billingInformation.TotalDue,
            BankName: this.achInformation.bankName,
            AccountNumber: this.achInformation.accountNumber,
            RoutingNumber: this.achInformation.routingNumber,
            AccountName: this.achInformation.accountName,
            AccountType: this.achInformation.accountType,
            CustomerId: this.quoteNum
          };

          this.billingService.processAch(achModel).subscribe(response => {
              if (!response.success) {
                this.showSpinner = false;
                this.achErrors = '';
                this.achErrors = `<div>${response.data}</div>`;
                return true;
              } else {
                this.billingService.bindQuote(bindObject).subscribe(response => {
                  sessionStorage.setItem('policyNumber', response);

                  this.redirectService.xenithRedirect(response);
                });
              }
          });
          break;
        case 2:
          this.billingService.bindQuote(bindObject).subscribe(response => {
            sessionStorage.setItem('policyNumber', response);

            this.redirectService.xenithRedirect(response);
          });
          break;
      }
    }
  }

  setRiskAddress(response) {
    this.riskAddress = response.Street1 + ((response.Street2 == null || response.Street2 == "") ? "" : (" " + response.Street2)) + " " + response.City + ", " + response.State + " " + response.Zip;

    if (this.billingService.isDisabledCounty(response.County)) {
      this.disabledCounty = true;
    }
  }

  openMoratoriumWarningDialog() {
    this.moratoriumWarningDialogRef = this.dialog.open(MoratoriumWarningDialog, {
      width: '30%',
      data: 'County is currently under a moratorium. Cannot bind this quote.'
    });
  }
}
