import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[quotableState][formControlName],[quotableState][formControl],[quotableState][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomQuotableStateDirective, multi: true }]
})

export class CustomQuotableStateDirective implements Validator {

  validate(c: FormControl): { [key: string]: any } {
    let v = c.value;

    if (v == null || v == undefined) {
      return null;
    }

    return v.FlgQuotable == false ? { "quotableState": true } : null;
  }
}
