import { Component, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MoratoriumManagementService } from "src/app/moratorium-management/moratorium-management.service";

@Component({
  selector: 'moratorium-warning-dialog',
  templateUrl: 'moratorium-warning-dialog.html'
})
export class MoratoriumWarningDialog {

  constructor(public dialogRef: MatDialogRef<MoratoriumWarningDialog>, @Optional() @Inject(MAT_DIALOG_DATA) public data: string, private moratoriumManagementService: MoratoriumManagementService){}

  onCancel(): void {
    this.dialogRef.close();
  }
}
