import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DropDownDTO } from '../models/drop-down-dto';
import { PreBindFileTypeEnum } from '../models/enums/pre-bind-file-type-enum';
import { FloridaFileDTO } from '../models/florida-file-dto';

@Component({
  selector: 'app-drag-and-drop-file',
  templateUrl: './drag-and-drop-file.component.html',
  styleUrls: ['./drag-and-drop-file.component.scss']
})
export class DragAndDropFileComponent{

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @ViewChildren("fileSelect") fileSelects : QueryList<ElementRef>;

  @Input() disabled: boolean = false;
  @Input() set selectFiles(value : DropDownDTO[]){
    this.staticSelectFiles = value;
    this.dynamicSelectFiles = value;
  }

  get selectFiles() : DropDownDTO[]{
    return this.staticSelectFiles;
  }

  @Output() onFilesLoaded = new EventEmitter<Array<FloridaFileDTO>>();

  files: FloridaFileDTO[] = [];
  staticSelectFiles: DropDownDTO[];
  dynamicSelectFiles: DropDownDTO[];

  onFileDropped($event) {
    if(this.disabled){
      return;
    }
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    if(this.disabled){
      return;
    }

    this.prepareFilesList(files);
  }


  public setFiles(files) {
    this.prepareFilesList(files, true);
  }

  public clearFiles() {
    this.dynamicSelectFiles = this.staticSelectFiles;
    this.files = [];
    this.onFilesLoaded.next(this.files);
  }

  onSelectType(type, index){
    this.files[index].type = type;
    this.onFilesLoaded.next(this.files);
  }

  deleteFile(index: number) {
    if (this.files[index].file.progress < 100) {
      return;
    }
    this.files.splice(index, 1);

    this.onFilesLoaded.next(this.files);
  }


  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].file.progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].file.progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }


  prepareFilesList(files: Array<any>, preloadedFiles: boolean = false) {
    if(!files || files.length <= 0){
      return;
    }

    const currentLength = this.files.length;

    if((this.selectFiles.length > 0 && currentLength == this.selectFiles.length) || (this.selectFiles.length > 0 && (files.length + currentLength) > this.selectFiles.length)){
      return;
    }

    for (const item of files) {
      if(preloadedFiles){
        item.file.progress = 0
        this.files.push(item);
        continue;
      }

      item.progress = 0;
      this.files.push({
        file: item,
        type: PreBindFileTypeEnum.None
      });
    }

    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);

    if(preloadedFiles == false){
      this.onFilesLoaded.next(this.files);
    }


  }

  getSelectTypeValue(index: number){
    const currentSelect = this.fileSelects.find(x => x.nativeElement.id == index.toString());
    if(currentSelect && this.files[index].type != PreBindFileTypeEnum.None && currentSelect.nativeElement.value as Number == PreBindFileTypeEnum.None.valueOf()){
      currentSelect.nativeElement.value = this.files[index].type;
    }
  }

  downloadFile(index: number){
    const file = this.files[index];
    this.getBase64(file.file).then((result) => {
      var blobContent = this.convertBase64StringToBlob(result as string, "application/pdf");
      let url = window.URL.createObjectURL(blobContent);
      window.open(url);
    });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  private convertBase64StringToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
