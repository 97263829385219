import { UserDto } from "./userDto.model";

export class Application {
  appId: number;
  user: UserDto;
  firstName: string;
  lastName: string;
  username: string;
  agencyNumber: string;
  riskAddressStreet1: string;
  riskAddressStreet2: string;
  riskAddressCity: string;
  riskAddressState: number;
  riskAddressStateName: string;
  riskAddressZip: string;
  riskAddressCounty: string;
  mailingAddressStreet1: string;
  mailingAddressStreet2: string;
  mailingAddressCity: string;
  mailingAddressState: number;
  mailingAddressStateName: string;
  mailingAddressZip: string;
  dwellingTiv: number;
  personalPropertyTiv: number;
  personalPropertyCostValueType: number;
  occupancy: number;
  construction: number;
  buildingPurpose: number;
  yearBuilt: number;
  noOfStories: number;
  floorArea: number;
  isElevated: boolean;
  hasBasement: boolean;
  completionStatus: number;
  foundationType: number;
  elevationHeight: number;
  garage: boolean;
  garageStatus: number;
  crawlspace: boolean;
  crawlspaceStatus: number;
  enclosure: boolean;
  enclosureStatus: number;
  buildingOverWater: number;
  priorFloodLosses: HiscoxPriorFloodLossesDto[];
  houseElevatedAfterPriorFloodLoss: boolean;
  twicPolicyNum: string;
  elevationDetailDto: ElevationDetailDto[];
  femaFloodZoneId: number;
}

export class HiscoxPriorFloodLossesDto {
  yearOfLoss: number;
  valueOfLoss: number;
}

export class ElevationDetailDto {
  id: number;
  applicationId: number;
  elevationTypeId: number;
  completionStatusId: number;
  flgVoid: boolean;
  createdBy: string;
  updatedBy: string;
  createdDate: Date;
  updatedDate: Date;
}
