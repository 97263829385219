import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { UnquotableZips } from "../shared/unquotableZips";

@Directive({
  selector: '[unquotableZip][formControlName],[unquotableZip][formControl],[unquotableZip][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomUnquotableZipDirective, multi: true }]
})

export class CustomUnquotableZipDirective implements Validator {
  @Input() unquotableZip: string;

  validate(c: FormControl): { [key: string]: any } {
    let v = c.value;
    return (UnquotableZips.zips.indexOf(v) > -1) ? { "unquotableZip": true } : null;

  }
}
