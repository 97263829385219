import { Injectable, Inject } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";

  constructor(@Inject('ARC_WEB_URL') private arcWebUrl: string) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    req = this.addAuthenticationToken(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          sessionStorage.clear();
          window.location.href = this.arcWebUrl;
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If you are calling an outside domain then do not add the token.
    if (!request.url.match(/api\/1000\/hiscox/)) {
      return request;
    }

    let token = sessionStorage.getItem('user_token');

    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, "Bearer " + token)
    });
  }
}
